import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { Ticket } from '../interfaces/ticket';
import { TicketService } from '../services/ticket.service';
import { TicketMessage } from '../interfaces/ticket-message';
import * as moment from 'moment';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
  ReactiveFormsModule,
} from '@angular/forms';
import { UserDataService } from '../services/user-data.service';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
import { ModalComponent } from './modal.component';
import { DialogStatus } from '../enums/dialog-status';
import { ErrorService } from '../services/error.service';
import { TranslateModule } from '@ngx-translate/core';
import { LoadingDirective } from '../directives/loading.directive';
import { QuillModule } from 'ngx-quill';
import { InputFileComponent } from './input-file.component';
import { FormGroupComponent } from './form-group.component';
import { LoaderComponent } from './loader.component';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { NgIf, NgFor, DatePipe, NgClass } from '@angular/common';
import { AccessDirective } from '../directives/access.directive';
import { InlineSVGModule } from 'ng-inline-svg-2';
import { FormChangeDetectorDirective } from '../directives/form-change-detector.directive';
import { VersionDirective } from '../directives/version.directive';
import { ThemeService } from '../services/theme.service';
import Quill from 'quill';
import { HttpClient } from '@angular/common/http';
import { cond } from 'cypress/types/lodash';

@Component({
  selector: 'app-conversation',
  templateUrl: 'conversation.component.html',
  standalone: true,
  imports: [
    InlineSVGModule,
    AccessDirective,
    ReactiveFormsModule,
    NgIf,
    NgClass,
    InfiniteScrollModule,
    FormChangeDetectorDirective,
    NgFor,
    ModalComponent,
    VersionDirective,
    LoaderComponent,
    FormGroupComponent,
    InputFileComponent,
    QuillModule,
    LoadingDirective,
    DatePipe,
    TranslateModule,
  ],
})
export class ConversationComponent implements OnInit, OnDestroy {
  private static REFRESH_INTERVAL = 30000;
  private static SCROLL_DOWN_THRESHOLD = 30;

  @Input() opened = false;
  @Input() ticket: Ticket;
  @Input() modal: ModalComponent;
  @ViewChild('changeLinkModal', { static: true })
  public changeLinkModal: ModalComponent;

  loading = false;
  isRequestingFeedback = false;
  form: FormGroup;
  changeNameForm: FormGroup;
  typeControl: FormControl;
  chosenStatus: 'OPEN' | 'PENDING' | 'CLOSED' = 'PENDING';
  DialogStatus = DialogStatus;
  typeSelectError = false;
  typeControlError = false;
  fullScreen = false;
  uploading = false;
  conversationModules = {};
  private quillEditorRef: any;
  public linkForm: FormGroup;

  activeTicketInModal: Ticket;
  activeTicketMessageInModal: TicketMessage;
  changeNameModalOpen = false;
  attachmentReady = false;
  private version: number;
  public ready: boolean = false;
  public linkData: any;

  @ViewChild('scroll', { static: true }) private scroll: ElementRef;
  private timer: number;

  constructor(
    private ticketService: TicketService,
    private formBuilder: FormBuilder,
    private userDataService: UserDataService,
    private sanitizer: DomSanitizer,
    private http: HttpClient,
    private themeService: ThemeService,
    private changeDetector: ChangeDetectorRef,
    private errorService: ErrorService
  ) {
    this.createForms();
    this.createTypeControl();
  }

  async ngOnInit() {
    this.version = await this.themeService.getVersion();

    if (this.version === 2) {
      this.loadIcon('bold', 'assets/v2/img/icons/quill-bold.svg');
      this.loadIcon('italic', 'assets/v2/img/icons/quill-italic.svg');
      this.loadIcon('underline', 'assets/v2/img/icons/quill-underline.svg');
      this.loadIcon('link', 'assets/v2/img/icons/quill-link.svg');
      this.loadIcon('list-ordered', 'assets/v2/img/icons/quill-ordered.svg');
      this.loadIcon('list-bullet', 'assets/v2/img/icons/quill-unordered.svg');
    } else {
      this.ready = true;
    }

    this.conversationModules = {
      clipboard: {
        matchVisual: false,
      },
      toolbar: {
        container: '#quill-toolbar-' + this.ticket.id,
      },
    };

    this.typeControl.patchValue(this.ticket.type);
    this.form.get('status').patchValue(this.ticket.status);
    this.setupRefreshTimer();

    if (!this.ticket.messages) {
      // don't load saved messages again
      this.loading = true;
      this.ticket.messages = await this.loadTickets('all');

      this.loading = false;
      this.ticketService.updateTicket(this.ticket);
    }

    if (this.ticket.open) {
      const body = document.getElementsByTagName('body')[0];
      body.classList.add('conversation-opened');
    }

    this.scrollToBottom();
  }

  ngOnDestroy() {
    clearInterval(this.timer);
  }

  /**
   * Toggle current conversation state
   */
  toggle() {
    this.ticket.open = !this.ticket.open;

    this.ticketService.updateTicket(this.ticket);
    this.scrollToBottom();
  }

  toggleFullScreen() {
    this.fullScreen = !this.fullScreen;
  }

  /**
   * Open current ticket
   * @param $event
   */
  open($event) {
    if ($event.target.nodeName.toLowerCase() === 'img') {
      return;
    }

    this.ticket.open = true;
    this.ticketService.updateTicket(this.ticket);
    this.scrollToBottom();
  }

  /**
   * Removes ticket from list
   */
  close() {
    const body = document.getElementsByTagName('body')[0];
    body.classList.remove('conversation-opened');

    this.ticketService.closeTicket(this.ticket);
  }

  /**
   * Open an image in a modal
   * @param message
   */
  openAttachment(message: TicketMessage) {
    this.modal.open(this.getAttachmentUrl(message));
  }

  /**
   * Request feedback from the user after a ticket has been closed
   * @returns {Promise<void>}
   */
  async requestFeedback() {
    if (this.ticket.requestedFeedback) {
      return;
    }

    this.isRequestingFeedback = true;

    try {
      await this.ticketService.requestFeedback(this.ticket);

      this.ticket.requestedFeedback = true;
      this.ticketService.updateTicket(this.ticket);
    } catch (error) {
      this.errorService.logError(error);
    } finally {
      this.isRequestingFeedback = false;
    }
  }

  /**
   * Load more tickets
   */
  async loadMoreMessages() {
    if (this.ticket.historyLoaded || this.loading) {
      return;
    }

    const filtered = this.ticket.messages.filter((item) => !item.loading);
    const lastDate: Date = filtered[filtered.length - 1].createdAt;

    this.loading = true;
    const items = await this.loadTickets('before', lastDate);

    // store the position right before updating the UI
    const bottom =
      this.scroll.nativeElement.scrollHeight -
      this.scroll.nativeElement.scrollTop;
    this.ticket.messages = this.ticket.messages.concat(items);

    this.loading = false;
    this.ticketService.updateTicket(this.ticket);
    this.scrollFromBottom(bottom);
  }

  async retryDialog() {
    try {
      this.ticket.dialogSynchronisationStatus = DialogStatus.QUEUED;
      await this.ticketService.retryDialogSync(this.ticket);
    } catch (error) {
      this.errorService.logError(error);
      this.ticket.dialogSynchronisationStatus = DialogStatus.FAILED;
    } finally {
      this.ticketService.updateTicket(this.ticket);
    }
  }

  /**
   * Send a message
   */
  async send() {
    if (this.uploading) {
      return;
    }

    this.typeControlError = !this.typeControl.valid;

    if (this.form.valid && this.typeControl.valid) {
      const data = this.form.value;
      const ticket = {
        content: data.message,
        animate: true,
        projectUser: await this.userDataService.retrieveProjectUser(),
        createdAt: new Date(),
        loading: true,
        attachment: data.attachment,
        attachmentPreview: data.attachmentPreview,
      };

      let statusOnly = false;

      // possibly only the status was changed, so
      if (
        (data.message != null && data.message !== '') ||
        (data.attachment != null && data.attachment !== '')
      ) {
        this.ticket.messages = [ticket].concat(this.ticket.messages as any);
        this.scrollToBottom();
      } else {
        statusOnly = true;
      }

      this.form.reset();
      this.attachmentReady = false;

      if (!statusOnly) {
        this.form.get('status').patchValue('PENDING');
        this.ticket.status = 'PENDING';
      } else {
        this.form.get('status').patchValue(data.status);
        this.ticket.status = data.status;
      }

      await this.ticketService.reply(
        this.ticket,
        ticket,
        this.ticket.status,
        statusOnly
      );
      ticket.loading = false;

      this.ticketService.updateTicket(this.ticket);

      if (data.status === 'CLOSED') {
        this.requestFeedback();
        this.scrollToBottom();
      }
    }
  }

  /**
   * @param message
   * @returns {SafeStyle|string}
   */
  public getAttachmentImage(message: TicketMessage): SafeStyle | string {
    return this.sanitizer.bypassSecurityTrustStyle(
      'url(' + this.getAttachmentUrl(message) + ')'
    );
  }

  /**
   * Set attachment to be a base64 encoded image, because server can't show it yet
   */
  public setAttachmentPreview(data) {
    this.form.get('attachmentPreview').patchValue(data);
  }

  /**
   * @returns {boolean}
   */
  public messageIsEmpty(): boolean {
    const value = this.form.get('message').value;
    const attachment = this.form.get('attachment').value;

    return (
      (value == null || value === '') &&
      (attachment == null || attachment === '')
    );
  }

  /**
   * Load tickets
   * @param date
   * @param type
   * @returns {Promise<Ticket[]>}
   */
  private async loadTickets(
    type: 'all' | 'before' | 'after',
    date: Date = null
  ): Promise<TicketMessage[]> {
    this.fetchStatus();

    const messages = await this.ticketService.messages(this.ticket, type, date);
    const result: TicketMessage[] = messages['hydra:member'];

    for (const message of result) {
      message.createdAt = moment(message.createdAt).toDate();
    }

    if (messages['hydra:totalItems'] === result.length && type !== 'after') {
      this.ticket.historyLoaded = true;
    }

    return result;
  }

  /**
   * Check if status was updated
   * @returns {Promise<void>}
   */
  private async fetchStatus() {
    const statusData = await this.ticketService.status(this.ticket);

    this.ticket.status = statusData.status;
    this.ticket.feedback = statusData.feedback;
    this.ticket.dialogSynchronisationStatus =
      statusData.dialogSynchronisationStatus;
    this.ticket.dialogSynchronisationDate =
      statusData.dialogSynchronisationDate;

    this.ticketService.updateTicket(this.ticket);
  }

  /**
   * Initialize the refresh timer
   */
  private setupRefreshTimer() {
    this.timer = setInterval(async () => {
      if (!this.ticket.messages || this.ticket.messages.length === 0) {
        return;
      }

      const firstDate: Date = this.ticket.messages.filter(
        (item) => !item.loading
      )[0].createdAt;

      const items = await this.loadTickets('after', firstDate);

      this.ticket.messages = items
        .filter((item) => {
          return (
            this.ticket.messages.filter((previous) => previous.id == item.id)
              .length === 0
          );
        })
        .concat(this.ticket.messages);

      this.ticketService.updateTicket(this.ticket);

      if (
        items.length > 0 &&
        this.scroll.nativeElement.scrollTop <=
          ConversationComponent.SCROLL_DOWN_THRESHOLD
      ) {
        this.scrollToBottom();
      }
    }, ConversationComponent.REFRESH_INTERVAL) as any;
  }

  private createTypeControl() {
    this.typeControl = this.formBuilder.control({}, Validators.required);
    this.typeControl.valueChanges.subscribe(async (value) => {
      if (value != null) {
        try {
          this.typeControlError = false;
          this.typeSelectError = false;

          await this.ticketService.updateType(this.ticket, value);

          this.ticket.type = value;
          this.ticketService.updateTicket(this.ticket);

          this.fetchStatus();
        } catch (error) {
          this.errorService.logError(error);

          this.typeSelectError = true;
        }
      }
    });
  }

  /**
   * Creates the form to send a message
   */
  private createForms() {
    this.form = this.formBuilder.group({
      message: [''],
      status: ['PENDING', Validators.required],
      attachment: [null],
      attachmentPreview: [null],
    });

    this.form.get('status').valueChanges.subscribe((value) => {
      this.chosenStatus = value;
    });

    this.changeNameForm = this.formBuilder.group({
      name: [''],
    });

    this.linkForm = this.formBuilder.group({
      link: [''],
    });
  }

  /**
   * Get attachment url
   * @param message
   * @returns {string}
   */
  private getAttachmentUrl(message: TicketMessage) {
    let url;

    if (message.attachmentPreview != null) {
      url = message.attachmentPreview;
    } else {
      url = message.attachmentThumbnails?.medium;
    }

    return url;
  }

  /**
   * Scroll chat container to the bottom
   */
  private scrollToBottom() {
    this.scrollFromBottom(0);
  }

  /**
   * Scroll chat container some pixels counted from the bottom
   * @param amount
   */
  private scrollFromBottom(amount: number) {
    setTimeout(() => {
      this.scroll.nativeElement.scrollTop =
        this.scroll.nativeElement.scrollHeight - amount;
    });
  }

  public startUploading() {
    this.uploading = true;
  }

  public stopUploading() {
    this.uploading = false;
    this.attachmentReady = true;
  }

  public openChangeNameModal(
    ticket: Ticket,
    ticketMessage: TicketMessage,
    version: number
  ) {
    if (version !== this.version) {
      return;
    }
    this.changeNameModalOpen = true;
    this.activeTicketInModal = ticket;
    this.activeTicketMessageInModal = ticketMessage;
    this.changeNameForm.patchValue({
      name: (() => {
        if (ticketMessage.name) {
          return ticketMessage.name;
        }
        if (ticketMessage.projectUser) {
          return (
            ticketMessage.projectUser.firstName +
            ' ' +
            ticketMessage.projectUser.lastName
          );
        }
        return ticket.name;
      })(),
    });
  }

  public async saveChangeNameForm() {
    this.errorService.markFormGroupTouchedAndDirty(this.changeNameForm);

    if (this.changeNameForm.valid) {
      this.loading = true;

      try {
        const data = this.changeNameForm.getRawValue();
        const ticketMessage = this.activeTicketMessageInModal;
        ticketMessage.name = data.name;

        await this.ticketService.updateTicketMessageName(
          this.activeTicketInModal,
          ticketMessage
        );
      } catch (error) {
        this.errorService.parseErrorsToForm(this.changeNameForm, error.error);
      } finally {
        this.loading = false;
        this.changeNameModalOpen = false;
      }
    }
  }

  private showCustomLinkTooltip() {
    const range = this.quillEditorRef.getSelection();
    this.linkData = range;
    const text = this.quillEditorRef.getText(range.index, range.length);
    if (text.length === 0) {
      return;
    }
    this.linkForm.get('link').setValue(text);
    this.changeLinkModal.open();
    this.changeDetector.detectChanges();
  }

  loadIcon(iconName: string, iconUrl: string): void {
    this.http.get(iconUrl, { responseType: 'text' }).subscribe((svgContent) => {
      const icons = Quill.import('ui/icons');
      icons[iconName] = svgContent;
      if (iconName === 'list-bullet') {
        this.ready = true;
      }
    });
  }

  changeLink() {
    const url = this.linkForm.get('link').value;
    if (url) {
      this.quillEditorRef.formatText(
        this.linkData.index,
        this.linkData.length,
        'link',
        url
      );
      this.changeLinkModal.close();
    }
  }

  editorCreated(editor: Quill) {
    if (this.version === 1) {
      return;
    }
    this.quillEditorRef = editor;
    const toolbar = editor.getModule('toolbar');
    toolbar.addHandler('link', () => this.showCustomLinkTooltip());
  }
}
