import {
  Component,
  ElementRef,
  HostListener,
  OnInit,
  QueryList,
  ViewChildren,
} from '@angular/core';
import { ProjectDataBusService } from 'src/app/services/project-data-bus.service';
import { Ticket } from '../../interfaces/ticket';
import { TicketService } from '../../services/ticket.service';
import { productFruits } from 'product-fruits';
import { UserDataService } from 'src/app/services/user-data.service';
import { environment } from 'src/environments/environment';
import { LocaleService } from 'src/app/services/locale.service';
import { ProductFruitUser } from 'src/app/interfaces/product-fruit';
import { ThemeService } from 'src/app/services/theme.service';
import { Subscription } from 'rxjs';
import { NgxSpinnerService, NgxSpinnerModule } from 'ngx-spinner';
import { ConversationComponent } from '../../components/conversation.component';
import { ModalComponent } from '../../components/modal.component';
import { FooterComponent } from '../../components/footer.component';
import { RouterOutlet } from '@angular/router';
import { BreadcrumbsComponent } from '../../components/breadcrumbs.component';
import { HeaderComponent } from '../../components/header.component';
import { NgIf, NgFor } from '@angular/common';
import { VersionDirective } from 'src/app/directives/version.directive';

declare global {
  interface Window {
    productFruits: any;
  }
}

@Component({
  selector: '[app-default]',
  templateUrl: 'default.component.html',
  standalone: true,
  imports: [
    NgxSpinnerModule,
    NgIf,
    VersionDirective,
    HeaderComponent,
    BreadcrumbsComponent,
    RouterOutlet,
    FooterComponent,
    ModalComponent,
    NgFor,
    ConversationComponent,
  ],
})
export class DefaultComponent implements OnInit {
  @ViewChildren('targetComp', { read: ElementRef })
  conversationComponents: QueryList<ElementRef>;

  private static SIZE_PER_CONVERSATION = 400;
  public openTickets: Ticket[] = [];
  private maximumAmountOfTickets: number;

  fullscreen: boolean = false;
  version: number;
  ready: boolean = false;
  themeSubscription: Subscription;

  constructor(
    private ticketService: TicketService,
    private localeService: LocaleService,
    private projectDataBusService: ProjectDataBusService,
    private userDataService: UserDataService,
    private themeService: ThemeService,
    private spinner: NgxSpinnerService
  ) {
    this.calculateMaximumAmountOfTickets();
    this.spinner.show();
    this.themeSubscription = this.themeService.cssLoadedSubject.subscribe(
      (version) => {
        if (version) {
          this.version = version;
          this.spinner.hide();
          this.ready = true;
        }
      }
    );
  }

  loadPf = () => {
    this.userDataService.retrieveUser().then((user) => {
      const fruitUser: ProductFruitUser = {
        username: user.id.toString(),
        firstname: user.projectUser.id.toString(),
      };
      if (user.activatedAt !== null) {
        fruitUser.signUpAt = user.activatedAt;
      }
      productFruits.init(
        environment.productFruits,
        this.localeService.currentLocale,
        fruitUser,
        { disableLocationChangeDetection: false }
      );
    });
  };

  @HostListener('document:click', ['$event'])
  onClick(event: MouseEvent) {
    let clickedInsideAnyComponent = false;

    this.conversationComponents.forEach((targetComp) => {
      const clickedInside = targetComp.nativeElement.contains(event.target);
      if (clickedInside) {
        clickedInsideAnyComponent = true;
      }
    });

    if (!clickedInsideAnyComponent) {
      this.openTickets.map((ticket) => {
        this.ticketService.closeTicket(ticket);
      });
    }
  }

  async ngOnInit() {
    this.loadPf();

    this.openTickets = Array.from(await this.ticketService.getOpenTickets());

    this.ticketService.ticketOpened.subscribe((ticket: Ticket) => {
      if (this.openTickets.length + 1 > this.maximumAmountOfTickets) {
        this.ticketService.closeTicket(this.openTickets[0]);
      }

      this.openTickets.push(ticket);
    });

    this.ticketService.ticketClosed.subscribe((ticket: Ticket) => {
      this.openTickets = this.openTickets.filter((item: Ticket) => {
        return item.id !== ticket.id; // object comparison won't work because of serialized objects
      });
    });

    this.projectDataBusService.isConceptObservable.subscribe((value) => {
      this.fullscreen = value;
    });
  }

  /**
   * Method to calculate how many conversations can be opened at once
   */
  private calculateMaximumAmountOfTickets() {
    this.maximumAmountOfTickets = Math.floor(
      window.screen.width / DefaultComponent.SIZE_PER_CONVERSATION
    );
  }
}

type PfWindow = {
  productFruits: any;
};
