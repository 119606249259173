import {
  Component,
  Input,
  forwardRef,
  EventEmitter,
  Output,
  ElementRef,
  ViewChild,
} from '@angular/core';
import {
  ControlValueAccessor,
  FormControl,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
} from '@angular/forms';
import { UploadService } from '../services/upload.service';
import { ModalComponent } from './modal.component';
import { resizeBase64ForMaxHeight } from 'resize-base64';
import { ErrorService } from '../services/error.service';
import { Upload } from '../interfaces/upload';
import { FileUploader } from 'ng2-file-upload';
import { TranslateService, TranslateModule } from '@ngx-translate/core';
import { Observable, Subscription } from 'rxjs';
import { ProgressbarModule } from 'ngx-bootstrap/progressbar';
import { InlineSVGModule } from 'ng-inline-svg-2';
import { NgIf } from '@angular/common';

@Component({
  selector: 'app-input-file-v2',
  templateUrl: 'input-file-v2.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputFileV2Component),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => InputFileV2Component),
      multi: true,
    },
  ],
  standalone: true,
  imports: [NgIf, InlineSVGModule, ProgressbarModule, TranslateModule],
})
export class InputFileV2Component implements ControlValueAccessor {
  private static readonly PREVIEW_MAX_HEIGHT = 256;
  private static readonly PREVIEW_MAX_WIDTH = 256;

  @Input() public type;
  @Input() dismiss: Observable<void>;
  @Input() uploadImages: boolean = false;
  @Input() uploadVideos: boolean = false;
  @Input() uploadPdfs: boolean = false;
  @Input() uploadJson: boolean = false;
  @Input() standalone: boolean = true;
  @Input() background: string;
  @Output() previewUpdated = new EventEmitter();
  @Output() videoChosen = new EventEmitter();
  @Output() startUploading = new EventEmitter();
  @Output() stopUploading = new EventEmitter();

  @ViewChild('inputFile') inputFile: ElementRef;
  public name: string;
  videoFileName: string;
  maxVideoSize: number = 275;
  maxPdfSize: number = 20;
  maxImageSize: number = 20;

  uploader: FileUploader = null;
  sasToken: string;
  @ViewChild('youtubeInput') youtubeInput: ElementRef;

  /**
   * @see https://stackoverflow.com/a/27728417/1646331
   * @type {RegExp}
   */
  private videoUrlRegex =
    /^.*(?:(?:youtu\.be\/|v\/|vi\/|u\/\w\/|embed\/)|(?:(?:watch)?\?v(?:i)?=|\&v(?:i)?=))([^#\&\?]*).*/;

  private imageTypes = {
    png: 'image/png',
    jpg: 'image/jpeg',
    jpeg: 'image/jpeg',
  };

  private pdfTypes = {
    pdf: 'application/pdf',
  };

  private jsonTypes = {
    json: 'application/json',
    ext: '.geojson',
    geojson: 'application/geo+json',
    txt: 'text/plain',
  };

  private videoTypes = {
    mp4: 'video/mp4',
    mov: 'video/quicktime',
  };

  public errors: any = null;

  public isLoading = false;
  public uploading = false;

  constructor(
    private uploadService: UploadService,
    private errorService: ErrorService,
    private translateService: TranslateService
  ) {
    this.uploader = new FileUploader({
      url: '',
      method: 'PUT',
      disableMultipart: true,
      autoUpload: false,
    });

    this.uploader.onAfterAddingFile = (file) => {
      if (this.uploader.queue.length > 1) {
        this.uploader.removeFromQueue(this.uploader.queue[0]);
      } else {
        this.uploading = true;
        this.startUploading.emit();
        this.uploadService
          .getSASToken(this.type, file.file.name)
          .then((res) => {
            this.videoFileName = res.file_name;
            this.uploader.setOptions({
              url: res.sas_token,
              headers: [
                { name: 'x-ms-blob-type', value: 'BlockBlob' },
                { name: 'Content-Type', value: file.file.type },
                { name: 'x-ms-blob-content-type', value: file.file.type },
              ],
            });
            this.uploader.uploadAll();
          })
          .catch((err) => {
            this.handleVideoError();
          });
      }
    };

    this.uploader.onCompleteAll = () => {
      this.stopUploading.emit();
      this.uploading = false;
      this.addVideo();
    };

    this.uploader.onErrorItem = () => {
      this.handleVideoError();
    };
  }

  propagateChange = (_: any) => {};
  propagateTouch = (_: any) => {};

  /**
   * Method to allow submit if file is wrong format (file will be empty)
   * @returns {void}
   */
  public ignoreInvalidFile(): void {
    if (this.errors != null && this.errors['uploadFileTypeInvalid'] != null) {
      delete this.errors['uploadFileTypeInvalid'];
    }
  }

  /**
   * Add a video as file
   */
  public addVideo() {
    if (Object.keys(this.errors).length) {
      return;
    }

    if (this.uploader.queue[0].isUploaded) {
      let copy = Object.assign({}, this.uploader.queue[0].file);
      this.videoChosen.emit({
        url: this.videoFileName,
        type: 'video',
        file: copy,
      });
    }

    this.inputFile.nativeElement.value = '';
    this.uploader.clearQueue();
  }

  /**
   * @returns {string[]}
   */
  public getExtensionTypes(): string[] {
    let result: string[] = [];
    if (this.uploadImages) result = result.concat(Object.keys(this.imageTypes));
    if (this.uploadPdfs) result = result.concat(Object.keys(this.pdfTypes));
    if (this.uploadJson) result = result.concat(Object.keys(this.jsonTypes));
    if (this.uploadVideos) result = result.concat(Object.keys(this.videoTypes));

    return result;
  }

  /**
   * @returns {string[]}
   */
  public getMimeTypes(): string[] {
    let result: string[] = [];
    if (this.uploadImages)
      result = result.concat(this.getMimeTypeByType(this.imageTypes));
    if (this.uploadPdfs)
      result = result.concat(this.getMimeTypeByType(this.pdfTypes));
    if (this.uploadJson)
      result = result.concat(this.getMimeTypeByType(this.jsonTypes));
    if (this.uploadVideos)
      result = result.concat(this.getMimeTypeByType(this.videoTypes));

    return result;
  }

  /**
   * @param {object} types
   * @returns {string[]}
   */
  private getMimeTypeByType(types: object): string[] {
    return Object.keys(types).map((key) => {
      return types[key];
    });
  }

  public isVideo(file: File) {
    return (
      /\.mp4$/i.test(file.name) ||
      /\blob:$/i.test(file.name) ||
      /\.mov$/i.test(file.name)
    );
  }

  /**
   * @returns {Promise<void>}
   */
  public async upload(files: File[]): Promise<void> {
    this.errors = {};

    if (files[0]) {
      const file: File = files[0];
      const reader = new FileReader();
      const extension = this.getExtension(file);

      if (this.isVideo(file) && this.uploadVideos) {
        if (file.size > this.maxVideoSize * 1024 * 2024) {
          this.handleVideoTooLarge();
        } else {
          this.uploader.addToQueue([file]);
        }
        return;
      }

      if (
        !this.hasCorrectMimeType(file) &&
        extension !== 'json' &&
        extension !== 'geojson'
      ) {
        // json will be parsed afterwards
        this.markInvalidFile();
      } else if (
        (extension == 'json' || extension == 'geojson') &&
        !this.uploadJson
      ) {
        this.markInvalidFile();
      } else if (
        extension === 'pdf' &&
        file.size > this.maxPdfSize * 1024 * 1024
      ) {
        this.handlePdfTooLarge();
      } else if (
        Object.keys(this.imageTypes).includes(extension) &&
        file.size > this.maxImageSize * 1024 * 1024
      ) {
        this.handleImageTooLarge();
      } else {
        this.isLoading = true;

        this.startUploading.emit();

        const upload = async () => {
          try {
            const uploaded: Upload = await this.uploadService.upload(
              this.type,
              file
            );

            this.isLoading = false;
            this.stopUploading.emit(uploaded);

            let result: any = uploaded.file;

            if (uploaded.base64ImagePreview) {
              this.previewUpdated.emit(uploaded.base64ImagePreview);
            }

            result = {
              preview: uploaded.base64ImagePreview,
              filePath: result,
              fileName: file.name,
            };

            this.propagateChange(result);
            this.propagateTouch(result);
          } catch (error) {
            this.handleErrors(error);
            this.isLoading = false;
            this.propagateChange(null);
            this.propagateTouch(null);
            this.stopUploading.emit(error);
          } finally {
            this.inputFile.nativeElement.value = ''; // clear
          }
        };

        reader.onloadend = async () => {
          const preview = reader.result as string;

          let data;

          if (extension === 'json' || extension === 'geojson') {
            try {
              data = atob((reader.result as string).split('base64,')[1]);

              JSON.parse(data);
            } catch (error) {
              this.errorService.logError(error);
              console.info('Invalid JSON-file supplied');

              this.markInvalidFile();
              return;
            }
          }
          this.previewUpdated.emit(preview);

          if (data == null) {
            data = atob(preview.split('base64,')[1]);
          }

          this.propagateChange(data);
          this.propagateTouch(data);
        };

        this.name = file.name;

        upload();
      }
    }
  }

  private handleVideoError() {
    this.errors['videoError'] = true;
    this.errors['message'] = this.translateService.instant(
      'form_group.video_error'
    );
    this.stopUploading.emit();
    this.removeItem();

    this.propagateChange(null);
    this.propagateTouch(null);
  }

  private handlePdfTooLarge() {
    this.errors['pdfMaxSizeExceeded'] = this.translateService.instant(
      'input_file_preview.pdf_size_error',
      { max: this.maxPdfSize }
    );

    this.inputFile.nativeElement.value = ''; // clear

    this.propagateChange(null);
    this.propagateTouch(null);
  }

  private handleVideoTooLarge() {
    this.errors['videoError'] = true;
    this.errors['message'] = this.translateService.instant(
      'form_group.max_size',
      { max: this.maxVideoSize }
    );

    this.inputFile.nativeElement.value = ''; // clear

    this.propagateChange(null);
    this.propagateTouch(null);
  }

  private handleImageTooLarge() {
    this.errors['imageMaxSizeExceeded'] = this.translateService.instant(
      'form_group.max_image_size',
      { max: this.maxImageSize }
    );

    this.inputFile.nativeElement.value = ''; // clear

    this.propagateChange(null);
    this.propagateTouch(null);
  }

  public reset() {
    this.errors = null;
    this.inputFile.nativeElement.value = '';
    this.propagateChange(null);
    this.propagateTouch(null);
  }

  /**
   * Mark file to be invalid
   */
  protected markInvalidFile() {
    this.errors = {
      uploadFileTypeInvalid: false,
    };

    this.inputFile.nativeElement.value = ''; // clear

    this.propagateChange(null);
    this.propagateTouch(null);
  }

  /**
   * @param file
   * @returns {string}
   */
  protected getExtension(file) {
    const name = file.name;
    const nameParts = name.split('.');

    return nameParts[nameParts.length - 1];
  }

  /**
   * @returns {boolean}
   */
  protected hasCorrectMimeType(file: File): boolean {
    const types: string[] = this.getMimeTypes();

    return (
      types.length === 0 ||
      this.getMimeTypes().indexOf(file.type.toString()) !== -1
    );
  }

  /**
   * @param obj
   */
  writeValue(obj: any): void {
    this.errors = null;

    if (obj == null) {
      this.name = null;
    }
  }

  /**
   * @param fn
   */
  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  /**
   * @param fn
   */
  registerOnTouched(fn: any): void {
    this.propagateTouch = fn;
  }

  /**
   * @param control
   * @returns {any}
   */
  validate(control: FormControl): any {
    control.markAsDirty();
    return this.errors;
  }

  async resizePreview(base64: string): Promise<string> {
    return new Promise((resolve, reject) => {
      resizeBase64ForMaxHeight(
        base64,
        InputFileV2Component.PREVIEW_MAX_WIDTH,
        InputFileV2Component.PREVIEW_MAX_HEIGHT,
        (result) => resolve(result),
        reject
      );
    });
  }

  removeFile(event) {
    this.uploading = false;

    this.propagateChange(null);
    this.propagateTouch(null);
    this.previewUpdated.emit(null);

    event.preventDefault();
    event.stopPropagation();
    event.stopImmediatePropagation();
  }

  removeItem() {
    this.uploader.clearQueue();
    this.inputFile.nativeElement.value = '';
    this.uploading = false;
  }

  cancel() {
    this.removeItem();
  }

  private handleErrors(error) {
    this.errors = {};

    // show error returned from server, as it will be a string containing entity violations
    if (error['hydra:description'] !== undefined) {
      this.errors['uploadServerError'] = error['hydra:description'];
    }

    if (error['_body'] !== undefined) {
      try {
        const errorJson = JSON.parse(error['_body']);

        if (!Array.isArray(this.errors['server'])) {
          this.errors['server'] = [];
        }

        this.errors['server'].push(errorJson['message']);
      } catch (error) {
        this.errorService.logError(error);
        console.log('Invalid JSON supplied');
      }
    }
  }
}
