import { Component, ViewChild } from '@angular/core';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { Project } from 'src/app/interfaces/project';
import { Update } from 'src/app/interfaces/update';
import { ProjectDataBusService } from 'src/app/services/project-data-bus.service';
import { UpdateService } from 'src/app/services/update.service';
import { UpdatesTileComponent } from '../updates/tile.component';
import { InlineSVGModule } from 'ng-inline-svg-2';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { AccessDirective } from 'src/app/directives/access.directive';
import { DatePipe, NgFor, NgIf } from '@angular/common';
import {
  ChartConfiguration,
  ChartData,
  ChartOptions,
  ChartType,
} from 'chart.js';
import { BaseChartDirective } from 'ng2-charts';
import { SurveyService } from 'src/app/services/survey.service';
import { TicketService } from 'src/app/services/ticket.service';
import { Ticket } from 'src/app/interfaces/ticket';
import { ThemeService } from 'src/app/services/theme.service';
import { StatisticsService } from 'src/app/services/statistics.service';
import { StatisticsType } from 'src/app/enums/statistics-type';
import { LoaderComponent } from 'src/app/components/loader.component';
import { ModalComponent } from 'src/app/components/modal.component';
import { SurveyQuestion } from 'src/app/interfaces/survey-question';
import { Module } from 'src/app/enums/module';

@Component({
  selector: 'app-default-projects-detail-dashboard',
  templateUrl: 'detail-dashboard.component.html',
  standalone: true,
  imports: [
    RouterLink,
    AccessDirective,
    NgFor,
    NgIf,
    TranslateModule,
    UpdatesTileComponent,
    InlineSVGModule,
    BaseChartDirective,
    DatePipe,
    LoaderComponent,
    ModalComponent,
  ],
})
export class DetailDashboardComponent {
  @ViewChild(ModalComponent, { static: true }) private modal: ModalComponent;
  project: Project;
  updates: Update[];
  tickets: Ticket[];
  surveyResponses: any[];
  charts: any[];
  loading: boolean = true;
  currentChartTitle: string;
  currentChartDescription: string;
  surveysEnabled: boolean;

  chartOptions: ChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      tooltip: {
        backgroundColor: '#ffffff',
        titleColor: '#212121',
        bodyColor: '#212121',
        bodyFont: { weight: 'normal', family: 'Mukta' },
        titleFont: { weight: 'bold', family: 'Mukta' },
        cornerRadius: 5,
        borderColor: '#e9e9e9',
        borderWidth: 1,
        padding: 8,
        boxPadding: 8,
      },
      legend: {
        display: false,
      },
      title: {
        display: false,
        text: '',
      },
    },
    scales: {
      x: {
        ticks: {
          autoSkip: true,
        },
        border: {
          width: 0,
        },
        grid: {
          display: false,
        },
      },
      y: {
        beginAtZero: true,
        ticks: {
          stepSize: 1,
        },
        border: {
          width: 0,
        },
        grid: {
          display: false,
        },
      },
    },
  };

  constructor(
    private projectDataBusService: ProjectDataBusService,
    private updateService: UpdateService,
    private surveyService: SurveyService,
    private ticketService: TicketService,
    private statisticsService: StatisticsService,
    private themeService: ThemeService,
    private router: Router,
    private route: ActivatedRoute,
    private translateService: TranslateService
  ) {
    this.themeService.getVersion().then((version) => {
      if (version == 1) {
        this.router.navigate(['general'], { relativeTo: this.route.parent });
      } else {
        this.projectDataBusService.projectObservable.subscribe((project) => {
          this.project = project;
          this.surveysEnabled = project.enabledModules.includes(Module.SURVEY);
          this.loadDashboard();
        });
      }
    });
  }

  ngOnInit() {}

  loadDashboard() {
    this.loading = true;
    this.charts = [];
    this.loadStatistics();
    this.loadLatestUpdates();
    this.loadLatestConversations();
    this.loadLatestSurveyResponses();
  }

  setupChartData(label: string, data: any) {
    const chartType = Object.values(data).length > 8 ? 'line' : 'bar';
    const labelCode = Object.keys(StatisticsType).find(
      (key) => StatisticsType[key] === label
    );
    const options: ChartOptions = structuredClone(this.chartOptions);
    if (chartType == 'line') options.scales.x.ticks['autoSkipPadding'] = 10;
    if (label === StatisticsType.RATING) {
      options.scales.y.min = 0;
      options.scales.y.max = 5;
    }
    const chart = {
      description: this.translateService.instant(
        'projects.detail.dashboard.chart.' + labelCode + '.desc'
      ),
      config: {
        options: options,
        type: chartType,
        data: {
          datasets: [
            {
              data: Object.values(data),
              label: this.translateService.instant(
                'projects.detail.dashboard.chart.' + labelCode
              ),
              fill: false,
              borderWidth: chartType == 'bar' ? 0 : 3,
              backgroundColor: '#f5b049',
              borderColor: '#f5b049',
              tension: 0.2,
              pointRadius: 0,
              minBarLength: 3,
            },
          ],
          labels: Object.keys(data),
        },
      },
    };
    this.charts.push(chart);
  }

  async loadStatistics() {
    try {
      const statistics = await this.statisticsService.getDashboardStatistics(
        this.project
      );
      for (const key in statistics) {
        this.setupChartData(key, statistics[key]);
      }
    } catch (e) {
    } finally {
      this.loading = false;
    }
  }

  async loadLatestSurveyResponses() {
    const response = await this.surveyService.getLastUniqueResponsesForProject(
      this.project
    );
    this.surveyResponses = response;
  }

  async loadLatestConversations() {
    const response = await this.ticketService.list(
      this.project,
      {
        sort: {
          field: 'recent',
          direction: 'desc',
        },
        search: '',
        startDate: '',
        endDate: '',
      },
      ['OPEN'],
      1,
      2,
      null
    );
    this.tickets = response['hydra:member'];
  }

  async loadLatestUpdates() {
    const response = await this.updateService.listByProjectV2(
      this.project,
      1,
      10
    );

    this.updates = response['hydra:member'];
  }

  openModal(index: number) {
    this.currentChartTitle = this.charts[index].config.data.datasets[0].label;
    this.currentChartDescription = this.charts[index].description;
    this.modal.open();
  }
}
