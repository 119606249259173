import {
  Component,
  HostListener,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  Validators,
  ReactiveFormsModule,
  AbstractControl,
} from '@angular/forms';
import { SmartReportingService } from '../../../../../services/smart-reporting.service';
import { Customer } from '../../../../../interfaces/customer';
import { SmartReportingTheme } from '../../../../../interfaces/smart-reporting-theme';
import { ProjectService } from '../../../../../services/project.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Project } from '../../../../../interfaces/project';
import { ErrorService } from '../../../../../services/error.service';
import { MapboxService } from 'src/app/services/mapbox.service';
import { MapComponent, NgxMapboxGLModule } from 'ngx-mapbox-gl';
import { MapMouseEvent } from 'mapbox-gl';
import { TranslateModule } from '@ngx-translate/core';
import { LoadingDirective } from '../../../../../directives/loading.directive';
import { InputFileComponent } from '../../../../../components/input-file.component';
import { FormGroupComponent } from '../../../../../components/form-group.component';
import { LoaderComponent } from '../../../../../components/loader.component';
import { NgIf, NgFor, NgClass } from '@angular/common';
import { FormChangeDetectorDirective } from 'src/app/directives/form-change-detector.directive';
import { ThemeService } from 'src/app/services/theme.service';
import { VersionDirective } from 'src/app/directives/version.directive';
import { InputFileV2Component } from 'src/app/components/input-file-v2.component';
import { QuillComponent } from 'src/app/components/quill.component';
import { platform } from '../../../../../services/platform.service';
import { ProjectDataBusService } from 'src/app/services/project-data-bus.service';
import { SafeStyle } from '@angular/platform-browser';

@Component({
  selector: 'app-notice-create',
  templateUrl: './notice-create.component.html',
  standalone: true,
  imports: [
    NgIf,
    FormChangeDetectorDirective,
    LoaderComponent,
    ReactiveFormsModule,
    FormGroupComponent,
    NgFor,
    NgClass,
    InputFileComponent,
    NgxMapboxGLModule,
    VersionDirective,
    LoadingDirective,
    TranslateModule,
    QuillComponent,
    InputFileV2Component,
  ],
})
export class SmartReportingNoticeCreateComponent implements OnInit, OnDestroy {
  @ViewChild(MapComponent) public mapComponent: MapComponent;

  private scrollListener: EventListener;
  public form: FormGroup;
  public themes: SmartReportingTheme[] = null;
  public customer: Customer;
  public mapOptions = null;
  public project: Project;
  public loading = false;
  public error = false;
  public locationError = false;
  public uploading = false;
  public version: number;
  public marker: mapboxgl.Marker;
  public ready: boolean = false;
  public locationLat: number;
  public locationLong: number;
  public stickySubmit: boolean = false;
  public imagePreview: string;

  platform = platform;

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private errorService: ErrorService,
    private smartReportingService: SmartReportingService,
    private projectService: ProjectService,
    private activatedRoute: ActivatedRoute,
    private themeService: ThemeService,
    private mapboxService: MapboxService,
    private projectDataBusService: ProjectDataBusService
  ) {
    this.createForm();

    this.themeService.getVersion().then((version) => {
      this.version = version;

      if (version === 1) {
        this.activatedRoute.params.subscribe((params) => {
          if (params.projectSlug) {
            const projectSlug = params.projectSlug;

            this.load(projectSlug);
          }
        });
      } else {
        this.activatedRoute.parent.params.subscribe((params) => {
          if (params['slug']) {
            this.load(params['slug']);
          }
        });
        this.projectDataBusService.projectObservable.subscribe((project) => {
          if (project) {
            this.project = project;
          }
        });
      }
    });
  }

  ngOnInit() {
    if (this.version === 1) return;

    this.scrollListener = (event) => this.onScroll(event);
    document
      .querySelector('.app__content')
      .addEventListener('scroll', this.scrollListener);
  }

  ngOnDestroy(): void {
    if (this.version === 1) return;

    document
      .querySelector('.app__content')
      .removeEventListener('scroll', this.scrollListener);
  }

  private onScroll(event) {
    this.stickySubmit = event.target.scrollTop >= 140;
  }

  public async submit() {
    if (this.uploading) {
      return;
    }

    this.errorService.markFormGroupTouchedAndDirty(this.form);

    if (!this.form.valid || this.loading) {
      if (!this.form.value.locationLat || !this.form.value.locationLong) {
        this.locationError = true;
      }

      return;
    }
    this.locationError = false;

    try {
      this.loading = true;
      this.error = false;

      const data = this.form.value;

      const notice: any = {
        project: this.project['@id'],
        description: data.description,
        email: data.email,
        name: data.name,
        phoneNumber: data.phoneNumber,
        theme: data.theme['@id'],
        attachment: data.attachment,
        locationLat: this.form.value.locationLat,
        locationLong: this.form.value.locationLong,
      };

      const result = await this.smartReportingService.createNotice(notice);

      this.smartReportingService.noticeStateChanged.next(true);

      this.router.navigateByUrl(`/smart-reporting/notices/${result.id}`);
    } catch (error) {
      console.error(error);
      this.error = true;
    } finally {
      this.loading = false;
    }
  }

  public mapClick($event: MapMouseEvent) {
    if ($event.lngLat) {
      this.form.patchValue({
        locationLat: $event.lngLat.lat,
        locationLong: $event.lngLat.lng,
      });
      this.placeMarker([$event.lngLat.lat, $event.lngLat.lng]);
    }
  }

  private placeMarker(location: number[]) {
    if (!this.marker) {
      this.marker = this.mapboxService.drawMarker(
        this.mapComponent,
        location,
        null,
        {},
        31,
        40
      );
    } else {
      this.marker.setLngLat([location[1], location[0]]);
    }
  }

  private createForm() {
    this.form = this.fb.group({
      theme: [null, Validators.required],
      description: [null, Validators.required],
      name: [null, Validators.required],
      attachment: [null],
      phoneNumber: [null],
      email: [null, Validators.required],
      locationLat: [null, Validators.required],
      locationLong: [null, Validators.required],
    });
  }

  private async fetchThemes() {
    this.themes = (
      await this.smartReportingService.listThemes(this.project.customer)
    )['hydra:member'];
  }

  private async load(projectSlug: string) {
    this.project = await this.projectService.fetch(projectSlug);

    this.locationLat = this.project?.locationLat ?? 52.3676;
    this.locationLong = this.project?.locationLong ?? 4.9041;
    this.ready = true;

    this.fetchThemes();
  }

  public startUploading() {
    this.uploading = true;
  }

  public stopUploading() {
    this.uploading = false;
  }

  public updateImagePreview(preview: string) {
    this.imagePreview = preview;
  }
}
