import { Component, OnInit, ViewChild } from '@angular/core';
import { SmartReportingTheme } from '../../../../../interfaces/smart-reporting-theme';
import { SmartReportingService } from '../../../../../services/smart-reporting.service';
import {
  FormBuilder,
  FormGroup,
  Validators,
  ReactiveFormsModule,
} from '@angular/forms';
import { ErrorService } from '../../../../../services/error.service';
import { ModalComponent } from '../../../../../components/modal.component';
import { Customer } from '../../../../../interfaces/customer';
import { ActivatedRoute } from '@angular/router';
import { CustomerService } from '../../../../../services/customer.service';
import { TranslateModule } from '@ngx-translate/core';
import { ConfirmDeleteComponent } from '../../../../../components/confirm-delete.component';
import { LoadingDirective } from '../../../../../directives/loading.directive';
import { FormGroupComponent } from '../../../../../components/form-group.component';
import { InlineSVGModule } from 'ng-inline-svg-2';
import { LoaderComponent } from '../../../../../components/loader.component';
import { NgIf, NgFor, NgClass, DatePipe } from '@angular/common';
import { FormChangeDetectorDirective } from 'src/app/directives/form-change-detector.directive';
import { VersionDirective } from 'src/app/directives/version.directive';

@Component({
  selector: 'app-smart-reporting-themes',
  templateUrl: './themes.component.html',
  standalone: true,
  imports: [
    NgIf,
    LoaderComponent,
    FormChangeDetectorDirective,
    NgFor,
    NgClass,
    InlineSVGModule,
    ModalComponent,
    ReactiveFormsModule,
    FormGroupComponent,
    LoadingDirective,
    ConfirmDeleteComponent,
    TranslateModule,
    VersionDirective,
    DatePipe,
  ],
})
export class SmartReportingThemesComponent {
  @ViewChild('formModal', { static: true }) public modal: ModalComponent;

  public themes: SmartReportingTheme[];
  public form: FormGroup;
  public formLoading = false;
  public formError = false;
  public customer: Customer;
  public mode: 'create' | 'edit' = 'create';
  public editingEntity: SmartReportingTheme;
  public version: number;

  constructor(
    private smartReportingThemeService: SmartReportingService,
    private customerService: CustomerService,
    private fb: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private errorService: ErrorService
  ) {
    this.createForm();

    this.activatedRoute.params.subscribe((params) => {
      if (params.id) {
        this.loadCustomer(params.id);
      }
    });
  }

  async submit($event) {
    $event.preventDefault();

    this.errorService.markFormGroupTouchedAndDirty(this.form);

    if (this.formLoading || !this.form.valid) {
      return;
    }

    try {
      this.formLoading = true;
      this.formError = false;

      if (this.mode === 'create') {
        await this.smartReportingThemeService.createTheme({
          customer: `/api/customers/${this.customer.id}`,
          ...this.form.value,
        });
      } else {
        await this.smartReportingThemeService.editTheme(this.editingEntity.id, {
          customer: `/api/customers/${this.customer.id}`,
          ...this.form.value,
        });
      }

      await this.load(this.customer);

      this.modal.close();
    } catch (error) {
      console.error(error);

      this.formError = true;
    } finally {
      this.formLoading = false;
    }
  }

  openEdit(theme: SmartReportingTheme) {
    this.mode = 'edit';
    this.editingEntity = theme;

    this.form.patchValue(theme);

    this.modal.open();
  }

  async deleteTheme(theme: SmartReportingTheme) {
    await this.smartReportingThemeService.deleteTheme(theme.id);
    this.load(this.customer);
  }

  private async load(customer: Customer) {
    const themes = await this.smartReportingThemeService.listThemes(
      this.customer
    );
    this.themes = themes['hydra:member'];
  }

  private createForm() {
    this.form = this.fb.group({
      title: ['', Validators.required],
      responseTimeDays: [0, [Validators.required, Validators.min(0)]],
    });
  }

  async loadCustomer(id: number): Promise<void> {
    this.customer = await this.customerService.fetch(id);

    this.load(this.customer);
  }

  public reset() {
    this.mode = 'create';
    this.formError = false;
    this.form.reset();
  }
}
