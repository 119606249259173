<div [ngClass]="{ container: isCustomer }">
  <app-loader *ngIf="!update"></app-loader>
  <div *ngIf="update" class="updates-view">
    <div class="bouwapp-header">
      <div class="bouwapp-header__text">
        <h1>
          {{ update.publicationDate | date : 'longDate' }}
          <span>{{ update.publicationDate | date : 'shortTime' }}</span>
        </h1>
      </div>
      <div class="bouwapp-header__buttons">
        <a class="delete" (click)="confirmDelete.confirmDelete(update)">
          {{ 'projects.detail.update.delete' | translate }}
        </a>
        <!-- Hide until we have a better update preview page
        <a class="secondary" [routerLink]="['']">
          {{ 'projects.detail.update.view' | translate }}
        </a>
        -->
        <a class="primary" [routerLink]="['edit']">
          {{ 'projects.detail.update.edit' | translate }}
        </a>
      </div>
    </div>

    <section class="updates">
      <div class="updates__view">
        <app-default-updates-tile
          [standalone]="true"
          [update]="update"
        ></app-default-updates-tile>
      </div>
    </section>

    <hr />

    <app-loader *ngIf="!ready"></app-loader>
    <section class="statistics" *ngIf="ready">
      <ng-container
        *ngTemplateOutlet="
          stat;
          context: {
            title: 'notifications',
            number: update.pushCount,
            average: update.project?.averagePushCount || 0
          }
        "
      ></ng-container>
      <ng-container
        *ngTemplateOutlet="
          stat;
          context: {
            title: 'views',
            number: update.viewCount,
            average: update.averageViews || 0
          }
        "
      ></ng-container>
      <ng-container
        *ngTemplateOutlet="
          stat;
          context: {
            title: 'opened',
            number: update.updateOpenCount,
            average: update.averageUpdateOpen || 0
          }
        "
      ></ng-container>

      <!-- Interactions -->
      <div class="chart chart__interactions">
        <app-statistic
          [type]="'bar'"
          [data]="interactionsChartData"
          [key]="'interactions'"
          [stacked]="true"
        ></app-statistic>
      </div>

      <!-- Reactions -->
      <div class="chart">
        <app-statistic
          [type]="'doughnut'"
          [data]="reactionsChartData"
          [key]="'reactions'"
          [hasIconLabels]="true"
        ></app-statistic>
      </div>

      <!-- User map -->
      <div class="stat stat__map">
        <h3>{{ 'projects.detail.update.stats.map' | translate }}</h3>
        <mgl-map
          #map
          (mapLoad)="mapLoaded($event)"
          [style]="'mapbox://styles/mapbox/streets-v11'"
        >
        </mgl-map>
      </div>

      <!-- Devices -->
      <div class="chart">
        <app-statistic
          [type]="'doughnut'"
          [data]="devicesChartData"
          [key]="'devices'"
        ></app-statistic>
      </div>

      <!-- Source -->
      <div class="chart">
        <app-statistic
          [type]="'doughnut'"
          [data]="sourcesChartData"
          [key]="'source'"
        ></app-statistic>
      </div>

      <!-- App -->
      <div class="chart">
        <app-statistic
          [type]="'doughnut'"
          [data]="appsChartData"
          [key]="'app'"
          [hasLabels]="true"
        ></app-statistic>
      </div>
    </section>
  </div>
</div>

<ng-template #stat let-title="title" let-number="number" let-average="average">
  <div class="stat">
    <h3>{{ 'projects.detail.update.stats.' + title | translate }}</h3>
    <span>{{ number }}</span>
    <span
      [hidden]="number === average || number === 0 || average === 0"
      [class.negative]="number < average"
      [class.positive]="number > average"
    >
      <span
        [inlineSVG]="
          '/assets/v2/img/icons/chevron-' +
          (number > average ? 'up' : 'down') +
          '.svg'
        "
      ></span>
      <span>
        {{ ((number - average) / average) * 100 | number : '1.0-0' }}%
      </span>
    </span>
  </div>
</ng-template>

<app-confirm-delete
  #confirmDelete
  (confirmed)="deleteConfirmed($event)"
></app-confirm-delete>
