import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  Validators,
  ReactiveFormsModule,
} from '@angular/forms';
import { ModalComponent } from 'src/app/components/modal.component';
import { ProjectUser } from 'src/app/interfaces/project-user';
import { ProjectUserService } from 'src/app/services/project-user.service';
import { HttpErrorResponse } from '@angular/common/http';
import { Project } from 'src/app/interfaces/project';
import { ProjectStatus } from 'src/app/enums/project-status';
import { ErrorService } from '../../services/error.service';
import { Customer } from '../../interfaces/customer';
import { ManageUsersService } from './manage-users.service';
import { ConfirmDeleteComponent } from '../confirm-delete.component';
import { AccessService } from 'src/app/services/access.service';
import { AccessControlList } from 'src/app/interfaces/access-control-list';
import { SecurityVoter } from 'src/app/security/security-voter';
import { TranslateModule } from '@ngx-translate/core';
import { CreateUserComponent } from './create-user.component';
import { LoadingDirective } from '../../directives/loading.directive';
import { NgSelectModule } from '@ng-select/ng-select';
import { FormGroupComponent } from '../form-group.component';
import { ModalComponent as ModalComponent_1 } from '../modal.component';
import { NgIf, NgFor } from '@angular/common';
import { InlineSVGModule } from 'ng-inline-svg-2';

@Component({
  selector: 'app-manage-users',
  templateUrl: 'manage-users.component.html',
  standalone: true,
  imports: [
    NgIf,
    ModalComponent_1,
    ReactiveFormsModule,
    FormGroupComponent,
    InlineSVGModule,
    NgFor,
    NgSelectModule,
    LoadingDirective,
    CreateUserComponent,
    ConfirmDeleteComponent,
    TranslateModule,
  ],
})
export class ManageUsersComponent implements OnChanges, OnInit {
  @ViewChild('create', { static: true }) private createModal: ModalComponent;

  @Input() projectUsers: ProjectUser[];
  @Input() customer: Customer;
  @Input() project: Project;
  @Input() loading: boolean;

  @Output() createUser: EventEmitter<{
    projectUser: ProjectUser;
    formData: any;
  }> = new EventEmitter<{ projectUser: ProjectUser; formData: any }>();
  @Output() editUser: EventEmitter<{
    projectUser: ProjectUser;
    formData: any;
  }> = new EventEmitter<{ projectUser: ProjectUser; formData: any }>();
  @Output() deleteUser: EventEmitter<ProjectUser> =
    new EventEmitter<ProjectUser>();
  @ViewChild('confirmDelete', { static: false })
  confirmDeleteComponent: ConfirmDeleteComponent;

  public radios: {
    id: string;
    value: string;
    label: string;
    hidden: boolean;
  }[] = [
    {
      value: 'customer-edit',
      label: 'user.admin.create.type.account.admin',
      id: 'typeAdmin',
      hidden: false,
    },
    {
      value: 'project-create',
      label: 'user.admin.create.type.project.admin',
      id: 'typeManager',
      hidden: false,
    },
    {
      value: 'project-view',
      label: 'project.detail.settings.users.table.type.project-reader',
      id: 'typeViewer',
      hidden: false,
    },
    {
      value: 'project-edit',
      label: 'project.detail.settings.users.table.type.project-user',
      id: 'typeUser',
      hidden: false,
    },
  ];

  public form: FormGroup;

  public editingMapping: ProjectUser;
  public inviteSaved: boolean = false;
  public cannotSave: boolean = false;
  public editSaved: boolean = false;
  public formLoading: boolean = false;
  public formSaved: boolean = false;
  public deleted: boolean = false;
  private acl: AccessControlList;
  public showCreateProjectUser: boolean = false;
  public editing: boolean = false;

  private validators: any = [Validators.required, Validators.email];

  constructor(
    private errorService: ErrorService,
    private fb: FormBuilder,
    private projectUserService: ProjectUserService,
    private manageUsersService: ManageUsersService,
    private accessService: AccessService
  ) {
    this.accessService.accessControlList.subscribe((acl) => {
      this.acl = acl;
    });

    this.form = this.fb.group({
      email: [null, this.validators],
      permissionType: [null, [Validators.required]],
      project: [null],
      customer: [null],
    });
  }

  getPrefix() {
    return !this.project ? 'user.admin' : 'project.detail.settings.users';
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.customer && !changes.customer.firstChange) {
      const projects = this.customer.projects.filter(
        (project) => project.status !== ProjectStatus.ARCHIVED
      );
    }
  }

  hideRadios(create: boolean = false) {
    if (!this.project) {
      this.radios.map((item) => {
        if (['typeViewer', 'typeUser'].includes(item.id)) {
          item.hidden = true;
        }
      });
      return;
    }
    if (
      create ||
      false ===
        SecurityVoter.isAccountAdminForCustomer(this.acl, this.project.customer)
    ) {
      this.radios.map((item) => {
        if (['typeAdmin', 'typeManager'].includes(item.id)) {
          item.hidden = true;
        }
      });
      return;
    }
    this.radios.map((item) => {
      item.hidden = false;
    });
  }

  getFormActionButtonText() {
    return !this.editing
      ? 'project.detail.settings.users.create.continue'
      : 'project.detail.settings.users.edit.save';
  }

  async ngOnInit(): Promise<void> {
    this.manageUsersService.deleteEvent$.subscribe((projectUser: any) => {
      this.confirmDeleteComponent.confirmDelete(projectUser);
    });

    this.manageUsersService.editEvent$.subscribe((projectUser: any) => {
      this.openModal(projectUser);
    });
  }

  public hasCustomerPermission(projectUser: ProjectUser) {
    return projectUser.customersProjectUser.some(
      (item) =>
        parseInt(item.customer.id as any) ===
          parseInt(this.customer.id as any) && item.permissionType !== 'view'
    );
  }
  private removeValidators() {
    this.form.get('email').clearValidators();
    this.form.get('email').updateValueAndValidity();
  }

  private addValidators() {
    this.form.get('email').setValidators(this.validators);
    this.form.get('email').updateValueAndValidity();
  }

  public openModal(projectUser?: ProjectUser): void {
    if (projectUser !== undefined) {
      this.hideRadios();

      this.editing = true;
      this.editingMapping = projectUser;
      if (!this.project) {
        this.form.patchValue({
          permissionType: this.hasCustomerPermission(projectUser)
            ? 'customer-edit'
            : 'project-create',
          project: this.editingMapping.projects
            .filter((project) => project.permissionType === 'create')
            .map((obj) => obj.project.id),
        });
      } else {
        const prefix = projectUser.admin ? 'customer-' : 'project-';
        this.form.patchValue({
          permissionType: prefix + projectUser.permission.permissionType,
          project: null,
        });
      }
    } else {
      this.hideRadios(true);

      this.form.patchValue({
        permissionType: null,
        project: null,
      });
      this.editing = false;
    }
    this.createModal.open();
  }

  public async check() {
    if (this.editing) {
      this.removeValidators();
    }
    this.errorService.markFormGroupTouchedAndDirty(this.form);
    if (!this.form.valid) {
      return;
    }

    this.formLoading = true;
    const formData = this.form.value;

    try {
      if (this.editing) {
        this.editUser.emit({
          projectUser: this.editingMapping,
          formData: formData,
        });
      } else {
        const user = await this.projectUserService.check(this.form.value.email);
        this.createUser.emit({
          projectUser: user.projectUser,
          formData: formData,
        });
      }
      this.createModal.close();
    } catch (error) {
      if ((error as HttpErrorResponse).status === 404) {
        this.showCreateProjectUser = true;
      } else {
        this.errorService.parseErrorsToForm(this.form, error.error, {
          'data.type': 'type',
        });
        console.error(error);
      }
    } finally {
      this.formLoading = false;
      this.addValidators();
    }
  }

  public reset(): void {
    this.form.reset();
    this.showCreateProjectUser = false;
  }

  public handleUserCreated(data: any) {
    this.createUser.emit({ projectUser: data, formData: this.form.value });

    this.reset();
    this.createModal.close();
  }

  public triggerDelete(projectUser: ProjectUser) {
    this.deleteUser.emit(projectUser);
  }
}
