<ngx-spinner
  size="medium"
  bdColor="white"
  color="black"
  type="ball-atom"
></ngx-spinner>

<div
  class="app"
  [class.fullscreen]="fullscreen"
  *ngIf="ready"
  [class.conversations__content]="openTickets.length > 0"
>
  <app-header></app-header>
  <div class="app__content">
    <app-breadcrumbs></app-breadcrumbs>

    <div class="app__content__wrapper">
      <router-outlet></router-outlet>
    </div>

    <app-footer></app-footer>

    <app-modal [image]="true" #imageModal></app-modal>

    <section
      class="tickets conversations"
      [class.conversations__overlay]="openTickets.length > 0"
    >
      <app-conversation
        #targetComp
        *ngFor="let ticket of openTickets"
        [ticket]="ticket"
        [modal]="imageModal"
      >
      </app-conversation>
    </section>
  </div>
</div>
