import {
  AfterViewChecked,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { ChartDataSet } from '../interfaces/chart-data-set';
import { CsvService } from '../services/csv.service';
import {
  FormArray,
  FormBuilder,
  FormGroup,
  ReactiveFormsModule,
} from '@angular/forms';
import { BaseChartDirective } from 'ng2-charts';
import { ColorHelper } from '../shared/color-helper';
import { ChartJsService } from '../services/chart-js.service';
import { Point } from '../interfaces/point';
import { TranslateService, TranslateModule } from '@ngx-translate/core';
import { SatisfactionDistributionComponent } from './satisfaction-distribution.component';
import { InlineSVGModule } from 'ng-inline-svg-2';
import { NgIf, NgFor, KeyValuePipe } from '@angular/common';

interface Choice {
  key: string;
  current: string;
  values: { [key: string]: string };
}

@Component({
  selector: 'app-chart',
  templateUrl: 'chart.component.html',
  standalone: true,
  imports: [
    NgIf,
    ReactiveFormsModule,
    NgFor,
    BaseChartDirective,
    InlineSVGModule,
    SatisfactionDistributionComponent,
    KeyValuePipe,
    TranslateModule,
  ],
})
export class ChartComponent implements AfterViewChecked, OnInit {
  static CHART_LINE = 'line';
  static CHART_BAR = 'bar';
  static CHART_AREA = 'area';
  static CHART_SATISFACTION_DISTRIBUTION = 'satisfaction-distribution';

  maximumValue: number = null;
  @Input() yAxisLabel = null;
  @Input() type = ChartComponent.CHART_LINE;
  @Input() set data(items: ChartDataSet[]) {
    this.dataSets = items;

    this.processItems(items, true);
    this.setChangeListener();
    this.checkAllToggle();
  }
  @Input() csvExport = true;
  @Input() toggle = true;
  @Input() title;
  @Input() full; // contains title and other additional data. needed for tooltips
  @Input() choices: Choice[];
  @Input() tooltip: (data, full) => string;
  @Input() public style = {
    font: 'Catamaran',
    fontStyle: 600,
    fontColor: '#212121',
    primary: '#f6b751',
  };
  @Input() set maximum(value) {
    this.hasFixedMaximum = true;
    this.maximumValue = value;
    (<any>this.options.scales.y.ticks).max = value;
  }
  @Input() set stripZeroValues(value) {
    this.hideZeroValues = value;
    this.processItems(this.dataSets);
  }
  @Output() public choicesChanged = new EventEmitter<object>();
  @ViewChild(BaseChartDirective) private chart;
  public primaryColor = getComputedStyle(
    document.documentElement
  ).getPropertyValue('--primary');
  public dataSetColors = [this.primaryColor, '#202020', '#77ced8', '#f5b03e'];
  public dataSetFillColors = [
    ColorHelper.generateRgbaColorsFromHex([this.primaryColor], 0.8)[0],
    'rgba(32,32,32, .8)',
    'rgba(119,206,216, .8)',
    'rgba(245,176,62, .8)',
  ];
  public dataSetAreaColors = [];
  public dataSetAreaBaseColors = [
    this.primaryColor,
    '#202020',
    '#628395',
    '#DD6E42',
    '#388697',
    '#BBDB9B',
  ];
  public dataSetAreaFillColors = [];
  public dataSetBorder = [[], [], [], [5, 10]];
  public dataSetForm: FormGroup;
  public responsePoints: Point[];

  hasFixedMaximum = false;
  hideZeroValues = false;
  chartData;
  chartColors;
  chartLabels: string[];
  dashedTitle: string[];
  dataSets: ChartDataSet[];
  choiceForm: FormGroup;
  allChecked: boolean;
  options = {
    interaction: {
      mode: 'nearest',
      intersect: false,
    },
    plugins: {
      tooltip: {
        cornerRadius: 2,
        backgroundColor: this.style.primary,
        yAlign: 'above',
        titleFont: {
          family: this.style.font,
          weight: this.style.fontStyle,
          size: 15,
          color: 'white',
        },
        titleSpacing: 0,
        bodySpacing: 0,
        footerSpacing: 0,
        footerFontSize: 0,
        bodyFontSize: 0,
        displayColors: false,
        callbacks: {
          title: (tooltipItems) => {
            if (this.tooltip) {
              return this.tooltip(
                tooltipItems[0].dataset.data[tooltipItems[0].dataIndex],
                this.full
              );
            }
          },
          label: () => {
            return '';
          },
          footer: () => {
            return '';
          },
        },
        xPadding: 11,
      },
      legend: {
        display: false,
      },
    },
    scales: {
      y: {
        stacked: this.type === ChartComponent.CHART_AREA,
        ticks: {
          beginAtZero: true,
          font: {
            size: 14,
            weight: this.style.fontStyle,
            color: this.style.fontColor,
            family: this.style.font,
          },
          max: this.maximumValue,
          callback: function (value, index, values) {
            return value === 0 || value % 1 !== 0 ? '' : value;
          },
          padding: 12,
        },
        grid: {
          drawTicks: false,
          border: {
            display: true,
          },
          color: '#e7e7e7',
        },
        scaleLabel: {
          display: this.yAxisLabel != null,
          labelString: this.yAxisLabel,
        },
      },
      x: {
        ticks: {
          font: {
            family: this.style.font,
            weight: this.style.fontStyle,
            color: this.style.fontColor,
            font: 14,
          },
        },
        grid: {
          display: false,
          border: {
            display: true,
          },
        },
      },
    },
    layout: {
      padding: {
        left: 0,
        top: 20,
      },
    },
    animation: {},
  };

  constructor(
    private csvService: CsvService,
    private formBuilder: FormBuilder,
    private chartJsService: ChartJsService,
    private fb: FormBuilder
  ) {
    this.createForm();
    this.chartJsService.initialize();
  }

  /**
   * Download a CSV-file from the graph
   */
  public exportCsv() {
    return this.csvService
      .create(this.createCsv())
      .download(this.title + '.csv');
  }

  public getType(): string {
    if (this.type === ChartComponent.CHART_AREA) {
      return ChartComponent.CHART_LINE; // chart js type
    } else {
      return this.type;
    }
  }

  /**
   * Export data as CSV object, for further use
   */
  public createCsv(): any {
    if (this.type === ChartComponent.CHART_SATISFACTION_DISTRIBUTION) {
      const output = {};

      for (let i = 1; i <= 5; i++) {
        output[i] = this.dataSets[i];
      }

      return [output];
    } else {
      const output = new Array(this.dataSets.length);

      for (let i = 0; i < this.dataSets.length; i++) {
        const data = { label: this.dataSets[i].label };

        for (const itemKey in this.dataSets[i].data) {
          if (!this.dataSets[i].data.hasOwnProperty(itemKey)) {
            continue;
          }

          const item = this.dataSets[i].data[itemKey];
          let cell = item.realValue != null ? item.realValue : item.y;

          if (cell != null && item.empty == null) {
            const tooltip = this.tooltip(item, this.full);

            if (
              String(cell) !== tooltip &&
              tooltip != null &&
              parseInt(tooltip) !== parseInt(cell)
            ) {
              cell += ' (' + tooltip + ')';
            }

            data[itemKey] = cell;
          } else {
            data[itemKey] = '';
          }
        }

        output[i] = data;
      }

      return output;
    }
  }

  public ngAfterViewChecked(): void {
    if (this.chart) {
      const chart = this.chart.chart;

      if (!chart.onResponseDataUpdated) {
        chart.onResponseDataUpdated = (points) => {
          this.responsePoints = points;
        };
      }
    }
  }

  public isDashed(index: number): boolean {
    return this.type !== ChartComponent.CHART_AREA && index === 3;
  }

  /**
   * Method 'stacks' data sets
   * @param {ChartDataSet[]} items
   */
  private createStackedDataSets(items: ChartDataSet[]): ChartDataSet[] {
    const valueHolder: any = {};

    for (let i = 0; i < items.length; i++) {
      const dataSet = items[i];
      const data = dataSet.data;

      Object.keys(data).forEach((key) => {
        if (valueHolder[key] == null) {
          valueHolder[key] = 0;
        }

        if (data[key].realValue == null) {
          data[key].realValue = data[key].y;
        }
        const dataSetItem = (<FormArray>this.dataSetForm.get('dataSets'))
          .controls[i];

        if (dataSetItem === undefined || dataSetItem.get('enabled').value) {
          valueHolder[key] += data[key].realValue;
        }

        data[key].y = valueHolder[key];
        data[key].x = key;
      });
    }

    return items;
  }

  /**
   * Convert data to correct structure
   * @param items
   * @param first First run, additional calculations
   */
  private processItems(items: ChartDataSet[], first: boolean = false) {
    if (!items) {
      return;
    }

    const labels = [];
    const data = [];
    const colors = [];

    if (!this.hasFixedMaximum) {
      this.maximumValue = 0;
    }

    if (this.type === ChartComponent.CHART_AREA) {
      items = this.createStackedDataSets(items);
      this.dataSetAreaColors = ColorHelper.generateColorStream(
        this.dataSetAreaBaseColors,
        6,
        items.length
      );
      this.dataSetAreaFillColors = ColorHelper.generateRgbaColorsFromHex(
        this.dataSetAreaColors,
        0.8
      );
    }

    for (let i = 0; i < items.length; i++) {
      const item = items[i];

      if ((<FormArray>this.dataSetForm.get('dataSets')).controls[i] == null) {
        this.addDataSetGroup(this.datasetIsEnabled(item));
      }

      for (const dataItemKey in item.data) {
        if (!item.data.hasOwnProperty(dataItemKey)) {
          continue;
        }

        if (labels.indexOf(dataItemKey) === -1) {
          labels.push(dataItemKey);
        }
        const dataValue =
          typeof item.data[dataItemKey].y !== 'number'
            ? item.data[dataItemKey].y
            : item.data[dataItemKey];
        // bar charts cannot have 'y' values, only plain numbers
        if (this.type === ChartComponent.CHART_BAR) {
          item.data[dataItemKey] = dataValue;
        }

        item.data[dataItemKey].x = dataItemKey;

        if (
          !this.hasFixedMaximum &&
          (dataValue.y > this.maximumValue || !this.maximumValue)
        ) {
          this.maximumValue = dataValue.y;
        }
      }
      data.push(this.createDataSetOptions(i, item));
    }

    if (!this.hasFixedMaximum) {
      this.maximumValue *= 1.2;
    }

    (<any>this.options.scales.y.ticks).max = this.maximumValue;
    (<any>this.options.scales.y.ticks).stepSize = Math.floor(
      this.maximumValue / 5
    );

    this.chartData = data;
    this.chartLabels = labels;

    if (this.hideZeroValues) {
      this.removeZeroValues();
    }

    this.redraw();
    this.disableAnimations();
  }

  /**
   * Create the data set in the correct format for ChartJS
   *
   *  @param index
   * @param data
   */
  private createDataSetOptions(index: number, data: ChartDataSet) {
    const result = [];

    for (const itemKey in data.data) {
      if (!data.data.hasOwnProperty(itemKey)) {
        continue;
      }

      result.push(data.data[itemKey]);
    }

    let ret = {
      label: data.label,
      data: result,
      backgroundColor:
        this.type === ChartComponent.CHART_AREA
          ? this.dataSetAreaFillColors[index]
          : this.dataSetFillColors[index],
      borderColor:
        this.type === ChartComponent.CHART_AREA
          ? this.dataSetAreaColors[index]
          : this.dataSetColors[index],
      pointBackgroundColor:
        this.type === ChartComponent.CHART_AREA
          ? this.dataSetAreaColors[index]
          : this.dataSetColors[index],
      fill:
        this.type === ChartComponent.CHART_BAR ||
        this.type === ChartComponent.CHART_AREA,
      borderWidth: 2,
      spanGaps: this.hideZeroValues,
      pointHoverRadius: 3,
      borderDash: this.dataSetBorder[index],
      pointRadius: 0,
      hidden: !(<FormArray>this.dataSetForm.get('dataSets')).controls[
        index
      ].get('enabled').value,
    };

    if (this.type === ChartComponent.CHART_AREA) delete ret.borderDash;
    return ret;
  }

  /**
   * Create a color set for an index of a data set
   *
   * @param index
   */
  public createDataSetColor(index: number) {
    if (this.type === ChartComponent.CHART_AREA) {
      return {
        borderColor: this.dataSetAreaColors[index],
        backgroundColor: this.dataSetAreaFillColors[index],
        pointBackgroundColor: this.dataSetAreaColors[index],
      };
    } else {
      return {
        borderColor: this.dataSetColors[index],
        backgroundColor: this.dataSetFillColors[index],
        pointBackgroundColor: this.dataSetColors[index],
      };
    }
  }

  public ngOnInit() {
    this.buildChoiceForm();

    this.dashedTitle = this.title.replace(/\s+/g, '-').toLowerCase();
  }

  public openPoint(point: Point) {
    if (point.open) {
      point.open = false;
    } else {
      this.responsePoints
        .filter((point) => point != null)
        .forEach((point) => (point.open = false));

      point.open = true;
    }
  }

  /**
   * Creates form for toggling datasets.
   */
  private createForm() {
    this.dataSetForm = this.formBuilder.group({
      dataSets: this.formBuilder.array([]),
    });
  }

  /**
   * Creates a dataset group and adds it
   */
  private addDataSetGroup(enabled = true) {
    const group = this.formBuilder.group({
      enabled: [enabled],
    });

    (<FormArray>this.dataSetForm.get('dataSets')).push(group);

    return group;
  }

  /**
   * Sets change listener after all initial values have been added
   */
  private setChangeListener() {
    this.dataSetForm.valueChanges.subscribe(() => {
      this.processItems(this.dataSets);
      this.checkAllToggle();
    });
  }

  /**
   * Redraw
   */
  private redraw() {
    setTimeout(() => {
      if (this.chart) {
        this.chart.ngOnChanges({} as SimpleChanges);
      }
    });
  }

  /**
   * Disable animations
   */
  private disableAnimations() {
    setTimeout(() => {
      // disable animations after the second time
      (<any>this.options.animation).duration = 0;
    });
  }

  /**
   * Strips 0 values. Cannot be done in the backend, because labels would'nt be preserved
   */
  private removeZeroValues() {
    for (const dataSet of this.chartData) {
      for (const item of dataSet.data) {
        if (item.y === 0) {
          item.y = null;
        }
      }

      // check if first item was set, otherwise guess it's value
      if (dataSet.data.length > 0) {
        const first = dataSet.data[0];

        if (first.y == null) {
          let nextValue = null;

          for (let i = 1; i < dataSet.data.length; i++) {
            if (dataSet.data[i].y != null) {
              nextValue = dataSet.data[i].y;
              break;
            }
          }

          first.y = nextValue;
          first.empty = true;

          // disable the point
          dataSet.pointRadius = [0, dataSet.pointRadius];
          dataSet.hoverPointRadius = [0, dataSet.hoverPointRadius];
        }
      }
    }
  }

  private buildChoiceForm() {
    if (!this.choices) {
      return;
    }

    const build: any = {};

    for (const choice of this.choices) {
      const key = choice.key;

      build[key] = [
        choice.current ? choice.current : Object.values(choice.values)[0],
      ];
    }

    this.choiceForm = this.fb.group(build);
    this.choiceForm.valueChanges.subscribe((value) =>
      this.choicesChanged.emit(value)
    );
  }

  private datasetIsEnabled(item: ChartDataSet) {
    if (item.id === null) {
      return true;
    }

    return ![
      'api.project.statistics.projects',
      'api.project.statistics.all',
    ].includes(item.id);
  }

  toggleAll(event) {
    (<FormArray>this.dataSetForm.get('dataSets')).controls.forEach(
      (control) => {
        control.get('enabled').patchValue(event.target.checked);
      }
    );
  }

  checkAllToggle() {
    let allChecked = true;
    (<FormArray>this.dataSetForm.get('dataSets')).controls.forEach(
      (control) => {
        if (!control.get('enabled').value) {
          allChecked = false;
        }
      }
    );
    this.allChecked = allChecked;
  }
}
