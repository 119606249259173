import { Component } from '@angular/core';
import { SmartReportingNotice } from '../../../../../../interfaces/smart-reporting-notice';
import {
  FormArray,
  FormBuilder,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { Subscription } from 'rxjs';
import { SmartReportingNoticeStatus } from '../../../../../../enums/smart-reporting-notice-status';
import { ActivatedRoute, RouterLink } from '@angular/router';
import { SmartReportingService } from '../../../../../../services/smart-reporting.service';
import { CsvService } from '../../../../../../services/csv.service';
import { TranslateService, TranslateModule } from '@ngx-translate/core';
import * as moment from 'moment';
import { Project } from '../../../../../../interfaces/project';
import { ProjectService } from '../../../../../../services/project.service';
import { LoaderComponent } from '../../../../../../components/loader.component';
import { AccessDirective } from '../../../../../../directives/access.directive';
import { NgIf, NgFor, DatePipe } from '@angular/common';
import { VersionDirective } from 'src/app/directives/version.directive';
import { ThemeService } from 'src/app/services/theme.service';
import { DeviceDetectorService } from 'ngx-device-detector';
import { InfiniteScrollDirective } from 'ngx-infinite-scroll';
import { dateRangeValidator } from 'src/app/shared/date-validator';
import { Sort } from 'src/app/interfaces/sort';
import { FormGroupComponent } from '../../../../../../components/form-group.component';
import { HttpParams } from '@angular/common/http';
import { ProjectDataBusService } from 'src/app/services/project-data-bus.service';
import { ProjectStatus } from 'src/app/enums/project-status';

@Component({
  selector: 'app-smart-reporting-notices-table',
  templateUrl: './table.component.html',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    NgIf,
    AccessDirective,
    RouterLink,
    LoaderComponent,
    NgFor,
    LoaderComponent,
    DatePipe,
    TranslateModule,
    VersionDirective,
    InfiniteScrollDirective,
    FormGroupComponent,
  ],
})
export class SmartReportingNoticesTableComponent {
  public notices: SmartReportingNotice[] = [];
  public SmartReportingNoticeStatus = SmartReportingNoticeStatus;
  public form: FormGroup;
  private noticesSubscription: Subscription;
  private countsSubscription: Subscription;
  public projectSlug: string;
  public project: Project;
  public page: number = 1;
  public perPage: number = 25;
  public totalItems: number = 0;
  public statusCounts: number[];
  private version: number;

  loading: boolean = false;
  search: string = '';
  public onProjectPage: boolean = false;
  public projects: Project[];
  public sortItems: Sort[] = [
    {
      field: 'updatedAt',
      direction: 'desc',
      label: 'smart_reporting.sort.direction.updated.descending',
    },
    {
      field: 'updatedAt',
      direction: 'asc',
      label: 'smart_reporting.sort.direction.updated.ascending',
    },
    {
      field: 'status',
      direction: 'desc',
      label: 'smart_reporting.sort.direction.status.descending',
    },
    {
      field: 'status',
      direction: 'asc',
      label: 'smart_reporting.sort.direction.status.ascending',
    },
    {
      field: 'theme',
      direction: 'desc',
      label: 'smart_reporting.sort.direction.theme.descending',
    },
    {
      field: 'theme',
      direction: 'asc',
      label: 'smart_reporting.sort.direction.theme.ascending',
    },
  ];

  constructor(
    private fb: FormBuilder,
    private projectService: ProjectService,
    private activatedRoute: ActivatedRoute,
    private smartReportingService: SmartReportingService,
    private csvService: CsvService,
    private themeService: ThemeService,
    private translateService: TranslateService,
    private formBuilder: FormBuilder,
    private projectDataBusService: ProjectDataBusService,
    deviceDetectorService: DeviceDetectorService
  ) {
    if (deviceDetectorService.isDesktop()) {
      this.perPage *= 2;
    }
    this.themeService.getVersion().then((version) => {
      this.version = version;
      if (version === 1) {
        this.activatedRoute.params.subscribe((params) => {
          if (params.projectSlug) {
            this.projectSlug = params.projectSlug;

            this.loadProject();
          }
        });

        this.form = this.fb.group({
          open: [true],
          inProgress: [true],
          closed: [true],
          deleted: [false],
        });

        this.form.valueChanges.subscribe((_) => {
          this.page = 1;
          this.notices = [];
          this.loadNotices();
        });
      } else {
        this.createForm();
        this.loadNotices();
        this.getStatusCounts();

        this.activatedRoute.parent.params.subscribe((params) => {
          if (!params['slug']) {
            this.onProjectPage = false;
          } else {
            this.onProjectPage = true;
          }

          this.projectDataBusService.shortListObservable.subscribe(
            (shortList) => {
              if (this.onProjectPage) {
                return;
              }

              this.projects = shortList?.filter(
                (project) => project.status === ProjectStatus.PUBLISHED
              );

              if (this.projects) {
                const formProjects = this.form.get('projects') as FormArray;
                formProjects.controls = [];

                this.projects.forEach(() =>
                  formProjects.push(this.formBuilder.control(false), {
                    emitEvent: false,
                  })
                );
              }
            }
          );

          this.projectDataBusService.projectObservable.subscribe((project) => {
            if (null === project || !this.onProjectPage) {
              return;
            }

            this.project = project;

            this.projects = [this.project];
            const formProjects = this.form.get('projects') as FormArray;
            formProjects.controls = [];
            formProjects.push(this.formBuilder.control(true));
          });
        });
      }
    });
  }

  protected async loadNotices() {
    if (this.noticesSubscription) {
      this.noticesSubscription.unsubscribe();
    }

    this.loading = true;

    this.noticesSubscription = this.smartReportingService
      .listNotices(this.setUrlParams())
      .subscribe((response) => {
        response['hydra:member'].forEach((v) => {
          v.lastResponseDate =
            v.responses.length > 0
              ? v.responses[v.responses.length - 1].createdAt
              : null;
        });

        this.notices = this.notices.concat(response['hydra:member']);
        this.totalItems = response['hydra:totalItems'];
        this.page += 1;

        this.loading = false;
      });
  }

  get activeStatuses(): SmartReportingNoticeStatus[] {
    const result: SmartReportingNoticeStatus[] = [];
    const value = this.form.value;

    if (value.open) {
      result.push(SmartReportingNoticeStatus.OPEN);
    }

    if (value.closed) {
      result.push(SmartReportingNoticeStatus.CLOSED);
    }

    if (value.deleted) {
      result.push(SmartReportingNoticeStatus.DELETED);
    }

    if (value.inProgress) {
      result.push(SmartReportingNoticeStatus.IN_PROGRESS);
    }

    return result;
  }

  public exportCsv() {
    return this.csvService
      .create(this.createCsv(), null, false)
      .download(`notices-export.csv`);
  }

  private createCsv(): any[] {
    const headerList = [
      'id',
      'createdAt',
      'status',
      'theme',
      'description',
      'attachment',
      'subscribers',
      'name',
      'phoneNumber',
      'email',
      'feedbackSolution',
      'feedbackProcess',
      'locationLat',
      'locationLong',
      'dialogue',
    ];

    const headers: any = {};

    headerList.forEach((item) => {
      headers[item] = this.translateService.instant(
        `smart_reporting.notices.export.${item}`
      );
    });

    return this.notices.map((item) => {
      const object: any = {};

      object[headers['id']] = item.id;
      object[headers['createdAt']] = moment(item.createdAt).format(
        'DD-MM-YYYY HH:mm'
      );
      object[headers['status']] = item.status;
      object[headers['theme']] = item.theme ? item.theme.title : '';
      object[headers['description']] = item.description;
      object[headers['attachment']] = item.attachment ? item.attachment : '';
      object[headers['subscribers']] = item.amountOfSubscribers;
      object[headers['name']] = item.name;
      object[headers['phoneNumber']] = item.phoneNumber;
      object[headers['email']] = item.email;
      object[headers['feedbackSolution']] = item.feedbackSolution;
      object[headers['feedbackProcess']] = item.feedbackProcess;
      object[headers['locationLat']] = item.locationLat;
      object[headers['locationLong']] = item.locationLong;
      object[headers['dialogue']] = this.translateService.instant(
        item.responses.length >= 2
          ? 'smart_reporting.notices.export.dialogue.yes'
          : 'smart_reporting.notices.export.dialogue.no'
      );

      return object;
    });
  }

  private async loadProject() {
    this.project = await this.projectService.fetch(this.projectSlug);

    await this.loadNotices();
  }

  getStatuses() {
    return Object.values(SmartReportingNoticeStatus);
  }

  public async getStatusCounts() {
    if (this.countsSubscription) {
      this.countsSubscription.unsubscribe();
    }

    this.countsSubscription = this.smartReportingService
      .getStatusCounts(this.setUrlParams())
      .subscribe((response) => (this.statusCounts = response));
  }

  private setUrlParams(): HttpParams {
    let params = new HttpParams();

    if (this.page && this.perPage) {
      params = params.set('page', this.page).set('perPage', this.perPage);
    } else {
      params = params.set('pagination', false);
    }

    const projectIds = this.project
      ? [this.project.id]
      : this.form
          .get('projects')
          .value.map((item, index) => (!item ? null : this.projects[index].id))
          .filter((it) => !!it);

    if (projectIds && projectIds.length > 0) {
      projectIds.forEach((id) => {
        params = params.append('project[]', id);
      });
    }

    let statuses = [];

    if (this.version === 1) {
      statuses = this.activeStatuses;
    } else {
      statuses = this.form.value.statuses
        .map((checked: boolean, index: number) =>
          checked ? Object.values(SmartReportingNoticeStatus)[index] : null
        )
        .filter((value: string | null) => value !== null);
    }

    if (statuses && statuses.length > 0) {
      statuses.forEach((status) => {
        params = params.append('status[]', status);
      });
    }

    if (this.search) {
      params = params.set('search', this.search);
    }

    if (this.version === 1) {
      params = params.set('order[updatedAt]', 'desc');
    } else {
      params = params.set(
        `order[${this.form.value.sort.field}]`,
        this.form.value.sort.direction
      );
    }

    return params;
  }

  private createForm() {
    const statuses = this.formBuilder.array(
      Object.values(SmartReportingNoticeStatus).map((item) => {
        const value =
          item !== SmartReportingNoticeStatus.DELETED &&
          item !== SmartReportingNoticeStatus.CLOSED
            ? item
            : null;
        return this.formBuilder.control(value);
      })
    );

    const projects = this.formBuilder.array([]);

    this.form = this.formBuilder.group(
      {
        search: [''],
        sort: [this.sortItems[0], Validators.required],
        statuses: statuses,
        projects,
        startDate: [''],
        endDate: [''],
      },
      { validator: dateRangeValidator() }
    );

    this.form.valueChanges.subscribe((item) => {
      this.page = 1;
      this.notices = [];

      if (this.projects || this.project) {
        if (
          this.search !== item.search ||
          this.onProjectPage ||
          this.version === 2
        ) {
          this.search = item.search;
          this.getStatusCounts();
          this.loadNotices();
        }
      }
    });
  }
}
