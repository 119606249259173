import { Component, OnInit } from '@angular/core';
import {
  FormArray,
  FormBuilder,
  FormGroup,
  Validators,
  ReactiveFormsModule,
} from '@angular/forms';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService, TranslateModule } from '@ngx-translate/core';
import { Article } from 'src/app/interfaces/article';
import { BlogCategory } from 'src/app/interfaces/blog-category';
import { Sort } from 'src/app/interfaces/sort';
import { NewsService } from 'src/app/services/news.service';
import { LoaderComponent } from '../../../components/loader.component';
import { InlineSVGModule } from 'ng-inline-svg-2';
import { InfiniteScrollDirective } from 'ngx-infinite-scroll';
import { NgIf, NgFor, NgStyle, NgClass, DatePipe } from '@angular/common';
import { DeviceDetectorService } from 'ngx-device-detector';

@Component({
  selector: 'app-news',
  templateUrl: './news.component.html',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    NgIf,
    NgFor,
    InfiniteScrollDirective,
    NgStyle,
    NgClass,
    InlineSVGModule,
    LoaderComponent,
    DatePipe,
    TranslateModule,
  ],
})
export class NewsComponent implements OnInit {
  articles: Article[];
  filterForm: FormGroup;
  categories: BlogCategory[] = [];
  categorySlugs: string[] | null;
  type: string;
  savedState: any;
  loading: boolean = false;

  public totalItems: number;
  private perPage = 8;
  private page = 0;
  private ready = false;

  sortItems: Sort[] = [
    {
      field: 'publishedAt',
      direction: 'desc',
      label: 'news.list.sort.direction.published.desc',
    },
    {
      field: 'publishedAt',
      direction: 'asc',
      label: 'news.list.sort.direction.published.asc',
    },
    {
      field: 'title',
      direction: 'desc',
      label: 'news.list.sort.direction.title.desc',
    },
    {
      field: 'title',
      direction: 'asc',
      label: 'news.list.sort.direction.title.asc',
    },
    {
      field: 'totalReads',
      direction: 'desc',
      label: 'news.list.sort.direction.totalReads.desc',
    },
    {
      field: 'totalReads',
      direction: 'asc',
      label: 'news.list.sort.direction.totalReads.asc',
    },
  ];

  private sort: Sort = this.sortItems[0];
  private search = null;

  constructor(
    private newsService: NewsService,
    private formBuilder: FormBuilder,
    private sanitizer: DomSanitizer,
    private router: Router,
    private route: ActivatedRoute,
    private translateService: TranslateService,
    deviceDetectorService: DeviceDetectorService
  ) {
    this.route.data.subscribe((data) => {
      this.type = data.type;
    });
    this.savedState = this.newsService.getSavedState();
    if (!this.savedState) this.createFilterForm();
    else {
      this.filterForm = this.savedState.filterForm;
      this.filterForm.valueChanges.subscribe(() => {
        this.updateFilter();
      });
      this.categories = this.savedState.categories;
      this.page = this.savedState.page;
      this.sort = this.savedState.sort;
      this.search = this.savedState.search;
      this.articles = this.savedState.articles;
      this.totalItems = this.savedState.totalItems;
      this.ready = true;
    }

    if (deviceDetectorService.isDesktop()) {
      this.perPage *= 2;
    }
  }

  async ngOnInit(): Promise<void> {
    if (!this.savedState) this.loadCategories();
  }

  private createFilterForm() {
    const categories = this.formBuilder.array([]);

    this.filterForm = this.formBuilder.group({
      sort: [this.sortItems[0], Validators.required],
      search: [''],
      categories,
    });

    this.filterForm.get('sort').setValue(this.sort);
    this.filterForm.valueChanges.subscribe(() => {
      this.updateFilter();
    });
  }

  public compareSort(sort1: Sort, sort2: Sort): boolean {
    return (
      sort1.field === sort2.field &&
      sort1.label === sort2.label &&
      sort1.direction === sort2.direction
    );
  }

  updateFilter() {
    this.sort = this.filterForm.get('sort').value as Sort;
    this.search = this.filterForm.get('search').value;
    this.categorySlugs = this.filterForm
      .get('categories')
      .value.map((item, index) => (!item ? null : this.categories[index].slug))
      .filter((it) => !!it);

    if (!this.ready) {
      return;
    }

    this.reset();
    this.loadArticles();
  }

  private loadCategories() {
    this.newsService.listCategoriesByType(this.type).then((categories: any) => {
      this.categories = categories;
      this.categories.push({
        title: this.translateService.instant('news.list.filter.favorite'),
        slug: 'favorite',
      });

      this.setupCategories(this.categories);

      this.ready = true;
      this.loadArticles();
    });
  }

  setupCategories(categories: BlogCategory[]) {
    const formCategories = this.filterForm.get('categories') as FormArray;
    formCategories.controls = [];

    this.categories.forEach((category) =>
      formCategories.push(this.formBuilder.control(false))
    );
  }

  async toggleFavorite(article: Article, event) {
    article.isFavorited = !article.isFavorited;
    event.preventDefault();
    event.stopPropagation();
    const result = await this.newsService.toggleFavorite(article.slug);
    if (result.action == 'ADDED') article.isFavorited = true;
    else if (result.action == 'REMOVED') article.isFavorited = false;
  }

  private reset() {
    this.page = 0;
    this.totalItems = undefined;
    this.articles = undefined;
  }

  async loadArticles() {
    this.loading = true;
    this.page += 1; // do before a request, so you won't get duplicates

    if (!this.articles || this.articles.length < this.totalItems) {
      const currentParams = this.currentParamHash;
      const response: any = await this.newsService.listByType(
        this.type,
        this.page,
        this.perPage,
        this.sort,
        this.search,
        this.categorySlugs
      );
      const result: Article[] = response['hydra:member'];

      if (result != null && currentParams === this.currentParamHash) {
        if (!this.articles) {
          this.articles = [];
          this.totalItems = response['hydra:totalItems'];
        }
        this.articles = this.articles.concat(result);
      }
    }
    this.loading = false;
  }

  openArticle(article: Article) {
    const saveState = {
      article: article,
      articles: this.articles,
      currentParams: this.currentParamHash,
      page: this.page,
      perPage: this.perPage,
      sort: this.sort,
      search: this.search,
      categories: this.categories,
      categorySlugs: this.categorySlugs,
      filterForm: this.filterForm,
      totalItems: this.totalItems,
    };
    this.newsService.setSavedState(saveState);
    this.router.navigate(['/articles', article.slug]);
  }

  get currentParamHash() {
    return JSON.stringify([this.sort, this.search, this.categories]);
  }

  getImageByArticle(article: Article): SafeStyle | string {
    if (article.image == null) {
      return '';
    } else {
      return this.sanitizer.bypassSecurityTrustStyle(
        'url(' + article.imageThumbnails?.medium + ')'
      );
    }
  }
}
