import { Component, OnInit, ViewChild } from '@angular/core';
import {
  FormArray,
  FormBuilder,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { TicketService } from '../../../services/ticket.service';
import { Ticket } from '../../../interfaces/ticket';
import { Project } from '../../../interfaces/project';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { TicketListComponent } from '../../../components/ticket-list.component';
import { LoaderComponent } from '../../../components/loader.component';
import { InfiniteScrollDirective } from 'ngx-infinite-scroll';
import { NgClass, NgFor, NgIf } from '@angular/common';
import { DeviceDetectorService } from 'ngx-device-detector';
import { VersionDirective } from 'src/app/directives/version.directive';
import { FormGroupComponent } from 'src/app/components/form-group.component';
import { DatePickerComponent } from 'src/app/components/date-picker.component';
import { ThemeService } from 'src/app/services/theme.service';
import { ProjectDataBusService } from 'src/app/services/project-data-bus.service';
import { ProjectStatus } from 'src/app/enums/project-status';
import { Sort } from 'src/app/interfaces/sort';
import { AccessDirective } from 'src/app/directives/access.directive';
import { ModalComponent } from 'src/app/components/modal.component';
import { ErrorService } from 'src/app/services/error.service';
import { UserDataService } from 'src/app/services/user-data.service';
import { LoadingDirective } from 'src/app/directives/loading.directive';
import { InlineSVGModule } from 'ng-inline-svg-2';
import { DetailGeneralContactComponent } from '../projects/detail-general/detail-general-contact.component';
import { ProjectService } from 'src/app/services/project.service';
import { DetailContactComponent } from '../projects/detail-contact.component';
import { dateRangeValidator } from 'src/app/shared/date-validator';

@Component({
  selector: 'app-default-tickets-all',
  templateUrl: 'all.component.html',
  standalone: true,
  imports: [
    NgIf,
    NgFor,
    NgClass,
    VersionDirective,
    InfiniteScrollDirective,
    AccessDirective,
    ModalComponent,
    DetailGeneralContactComponent,
    ReactiveFormsModule,
    DatePickerComponent,
    InlineSVGModule,
    FormGroupComponent,
    LoaderComponent,
    TicketListComponent,
    DetailContactComponent,
    TranslateModule,
    LoadingDirective,
  ],
})
export class AllComponent implements OnInit {
  @ViewChild('modal', { static: true }) modal: ModalComponent;

  filterForm: FormGroup;
  ticketForm: FormGroup;

  ticketTab: string;
  tickets: Ticket[] = [];
  search: string = '';
  loading: boolean = false;
  public isCustomer: boolean = false;
  public createTicket: boolean = false;

  protected version: number;
  protected project: Project;
  protected onProjectPage: boolean = false;

  protected projects: Project[];
  protected projectIds: number[];

  public sortItems: Sort[] = [
    {
      field: 'recent',
      direction: 'desc',
      label: 'project.detail.tickets.sort.direction.updated.descending',
    },
    {
      field: 'recent',
      direction: 'asc',
      label: 'project.detail.tickets.sort.direction.updated.ascending',
    },
  ];

  page: number = 1;

  statusCount;

  totalTickets = 0;

  perPage: number = 25;
  ready: boolean = false;

  private readonly STATUSES = ['OPEN', 'PENDING', 'CLOSED'];

  ticketToOpen: Ticket;
  @ViewChild(TicketListComponent) ticketList: TicketListComponent;

  constructor(
    protected formBuilder: FormBuilder,
    protected ticketService: TicketService,
    protected themeService: ThemeService,
    protected activatedRoute: ActivatedRoute,
    protected router: Router,
    protected projectService: ProjectService,
    protected userDataService: UserDataService,
    protected errorService: ErrorService,
    protected projectDataBusService: ProjectDataBusService,
    deviceDetectorService: DeviceDetectorService
  ) {
    this.createForms();
    this.activatedRoute.params.subscribe((params) => {
      if (params.id != null) {
        this.loadTicket(+params.id);
      }
    });

    this.ticketToOpen =
      this.router.getCurrentNavigation()?.extras?.state?.ticket;

    this.activatedRoute.parent.params.subscribe((params) => {
      if (!params['slug']) {
        this.onProjectPage = false;
      } else {
        this.onProjectPage = true;
      }

      this.projectDataBusService.shortListObservable.subscribe((shortList) => {
        this.ready = false;
        if (this.onProjectPage) {
          return;
        }

        this.projects = shortList?.filter(
          (project) => project.status === ProjectStatus.PUBLISHED
        );

        if (this.projects) {
          const formProjects = this.filterForm.get('projects') as FormArray;
          formProjects.controls = [];

          this.projects.forEach(() =>
            formProjects.push(this.formBuilder.control(false))
          );
        }
        this.ready = true;
      });
      this.projectDataBusService.projectObservable.subscribe((project) => {
        this.ready = false;
        if (null === project || !this.onProjectPage) {
          return;
        }

        this.project = project;
        this.projectIds = [this.project.id];

        this.projects = [this.project];
        const formProjects = this.filterForm.get('projects') as FormArray;
        formProjects.controls = [];
        formProjects.push(this.formBuilder.control(true));
        this.ready = true;
      });
    });

    if (deviceDetectorService.isDesktop()) {
      this.perPage *= 2;
    }
  }

  getStatuses() {
    return this.STATUSES;
  }

  downloadExport() {
    this.ticketService
      .downloadCsv()
      .then((result) => {
        const blob = new Blob([result], { type: 'text/csv' });
        const url = window.URL.createObjectURL(blob);
        window.open(url);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  async ngOnInit() {
    this.version = await this.themeService.getVersion();

    this.activatedRoute.parent.params.subscribe((params) => {
      if (!params['slug']) {
        this.isCustomer = true;
      }
    });

    this.activatedRoute.queryParams.subscribe((params) => {
      if (params.tab) this.ticketTab = params.tab;
      if (this.version === 1) {
        this.tickets = [];
        this.page = 1;
      }
      this.loadTickets();
      this.getStatusCount(true);
    });
  }

  public async getStatusCount(first: boolean = false) {
    if (this.loading && !first) {
      return;
    }
    this.statusCount = await this.ticketService.getStatusCount(
      this.filterForm.value,
      this.page,
      this.perPage,
      this.projectIds
    );
  }

  public getIsCustomer() {
    return this.isCustomer;
  }

  async saveTicket(): Promise<void> {
    this.errorService.markFormGroupTouchedAndDirty(this.ticketForm);
    this.loading = true;

    if (this.ticketForm.valid) {
      const data = this.ticketForm.getRawValue();
      const projectUser = await this.userDataService.retrieveProjectUser();
      data.project = '/api' + TicketService.base + '/' + this.project.slug;
      data.name = data.name || projectUser.firstName;
      data.messages = [];
      data.messages.push({
        content: data.content,
      });
      this.ticketService
        .createTicket(data)
        .then((ticket: Ticket) => {
          this.tickets.push(ticket);
          this.ticketForm.reset();
          this.modal.close();
        })
        .finally(() => {
          this.loading = false;
        });
    }
  }

  /**
   * Create form for filters and set listeners
   */
  protected createForms() {
    const statuses = this.formBuilder.array(
      this.STATUSES.map((item) => {
        const value = item !== 'CLOSED' ? true : false;
        return this.formBuilder.control(value);
      })
    );

    const projects = this.formBuilder.array([]);

    this.filterForm = this.formBuilder.group(
      {
        search: [''],
        sort: [this.sortItems[0], Validators.required],
        statuses,
        projects,
        startDate: [''],
        endDate: [''],
      },
      { validator: dateRangeValidator() }
    );

    this.ticketForm = this.formBuilder.group({
      subject: ['', Validators.required],
      content: ['', Validators.required],
      name: [],
    });

    this.filterForm.valueChanges.subscribe((item) => {
      if (this.ready) this.page = 1;

      this.projectIds = this.filterForm
        .get('projects')
        .value.map((item, index) => (!item ? null : this.projects[index].id))
        .filter((it) => !!it);

      if (this.projects || this.project) {
        if (
          this.search !== item.search ||
          this.onProjectPage ||
          this.version === 2
        ) {
          this.search = item.search;
          this.getStatusCount();
          this.loadTickets();
        }
      }
    });
  }

  protected navigateToContact() {
    this.router.navigate(['/projects', this.project.slug, 'contact']);
  }

  /**
   * Load all tickets
   * @param project
   * @returns {Promise<void>}
   */
  protected async loadTickets(): Promise<void> {
    if (this.loading || !this.filterForm.valid) {
      return;
    }
    this.loading = true;
    let statuses = [];
    if (this.version === 1 && !this.project && this.ticketTab) {
      statuses.push(this.ticketTab.toUpperCase());
    } else {
      statuses = this.filterForm.value.statuses
        .map((checked: boolean, index: number) =>
          checked ? this.STATUSES[index] : null
        )
        .filter((value: string | null) => value !== null);
    }

    const response: any = await this.ticketService.list(
      this.project,
      this.filterForm.value,
      statuses,
      this.page,
      this.perPage,
      this.projectIds
    );
    if (this.page > 1) {
      this.tickets = this.tickets.concat(response['hydra:member']);
    } else {
      this.tickets = response['hydra:member'];
    }
    this.totalTickets = response['hydra:totalItems'];
    this.page++;
    this.loading = false;

    if (this.ticketToOpen) {
      this.ticketList.openTicket(this.ticketToOpen, null);
    }
  }

  /**
   * @param id
   */
  protected async loadTicket(id: number) {
    const ticket = await this.ticketService.detail(id);
    ticket.open = true;

    this.ticketService.openTicket(ticket);
    this.router.navigateByUrl(
      this.router.url.replace(/tickets\/([0-9]+)/i, 'tickets')
    );
  }
}
